/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React from "react";
import { SwiperSlide } from "swiper/react";
import { ChevronRight } from "lucide-react";

import Carousel from "@/components/Carousel";
import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { AppButton } from "@/components/buttons/AppButton";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "../constants";
import CustomLink from "../CustomLink";
import { useLocale } from "@/store/clientStore";
import { AppIconButton } from "../buttons/AppIconButton";

const ProductDetailsCarousel = ({
  id,
  label,
  heading,
  subheading,
  ctaLabel,
  ctaLink,
  solutions,
  textAlign,
  ctaTheme = "primary",
  background,
}: any) => {
  const { labelColor, bgColorClass, textColor, bg } = colorsMap[background] ?? fallbackColors;
  const { locale } = useLocale();

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id} innerClass="sm:pb-20 pb-12 pt-0 sm:pt-0">
      <div className={cn("flex flex-col lg:flex-row gap-8 lg:gap-10")}>
        <div className={cn("flex flex-col w-full", textAlign === "Center" ? "items-center justify-center" : "")}>
          {label && (
            <AppText type={"LABEL_LARGE"} className={labelColor}>
              {label}
            </AppText>
          )}

          {heading && (
            <AppText
              type={"HEADLINE_MEDIUM"}
              className={cn("text-white mt-[8px] line-clamp-2", textAlign === "Center" && "text-center", textColor)}
            >
              {heading}
            </AppText>
          )}

          {subheading && (
            <AppText
              type={"SUB_HEADING_SMALL"}
              className={cn("text-white mt-[16px] line-clamp-3", textAlign === "Center" && "text-center", textColor)}
            >
              {subheading}
            </AppText>
          )}
        </div>

        {ctaLabel && ctaLink && textAlign !== "Center" && (
          <div className={cn(textAlign === "Left" && "flex lg:justify-end")}>
            <AppButton className="lg:mt-8" label={ctaLabel} link={ctaLink} intent={ctaTheme} bg={bg} />
          </div>
        )}
      </div>

      <Carousel
        wrapperClassName={"mt-14 flex"}
        className={"flex-1 flex items-stretch"}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={10}
        paginationType={"pageSelect"}
        bg={bg}
        breakpoints={{
          200: {
            slidesPerView: 1,
            spaceBetween: 16,
            slidesPerGroup: 1,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 16,
            slidesPerGroup: 1,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 16,
            slidesPerGroup: 1,
          },
        }}
      >
        {solutions.map((product: any) => renderProduct(product, locale, bg === "dark"))}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(ProductDetailsCarousel);

const renderProduct = (product: any, locale: string, darkBg: boolean) => {
  const { id, ctaLink, title, subheading, ctaLabel } = product;

  return (
    <SwiperSlide key={id} className="p-2">
      <CustomLink
        href={ctaLink}
        lang={locale}
        className={cn(
          `cursor-pointer flex flex-col overflow-hidden ring-0 focus:outline-royal-blue outline-offset-4 
          transition h-[100%] group`
        )}
      >
        <div
          className={cn("flex flex-col flex-1 py-6 px-4 items-center border-[1px] border-navy-blue-80 bg-[#fcfcfc0d]")}
        >
          <AppText className={cn("line-clamp-3 mb-4 text-white text-center")} type="TITLE_X_SMALL">
            {title}
          </AppText>
          <AppText className={cn("line-clamp-5 mb-4 text-white text-center")} type="BODY_SMALL">
            {subheading}
          </AppText>

          <AppIconButton
            tabIndex={-1}
            intent="ghost"
            label={ctaLabel || "Read more"}
            icon={<ChevronRight size={20} aria-label="chevron right icon" />}
            iconOnRightSide
            className="mt-auto"
            bg={darkBg ? "dark" : "light"}
          />
        </div>
      </CustomLink>
    </SwiperSlide>
  );
};
