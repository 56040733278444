"use client";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Image from "next/image";
import { SwiperSlide } from "swiper/react";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import RichTextRenderer from "./../RichTextRenderer";
import AppButton from "@/components/buttons/AppButton";
import Carousel from "./ProductDetailsHeaderSectionCustomCarousel";
import { cn } from "@/lib/utils";
import { useSwiper } from "@/hooks/useSwiper";

export interface Media {
  id: string;
  src: string;
  name: string;
  tags: any[];
  type: string;
  width: number;
  height: number;
  archive: number;
  brandId: string;
  limited: number;
  fileSize: number;
  isPublic: number;
  original: null;
  copyright: null;
  extension: string[];
  thumbnails: Thumbnails;
  dateCreated: Date;
  description: null;
  orientation: string;
  watermarked: number;
  dateModified: Date;
  datePublished: Date;
  videoPreviewURLs: any[];
  textMetaproperties: any[];
}

export interface Thumbnails {
  mini: string;
  thul: string;
  webimage: string;
  Outlook_Email: string;
  transformBaseUrl: string;
}

interface ProductDetailsHeaderSectionProps {
  label: string;
  heading: string;
  subheading: string;
  content: any;
  ctaLabel: string;
  ctaLink: string;
  media: Media[];
}

const renderImage = (imageUrl: string | undefined, alt: string | undefined, key: number) => {
  return (
    <SwiperSlide key={key} className="px-[0px] sm:px-[8px] h-auto">
      <Image
        src={imageUrl || "/placeholders/no-preview.png"}
        alt={alt || "image"}
        sizes="100vw"
        width={0}
        height={0}
        className="aspect-1.7 object-cover w-full max-h-[525px]"
      />
    </SwiperSlide>
  );
};

const ProductDetailsHeaderSection: React.FC<ProductDetailsHeaderSectionProps> = (props) => {
  const { label, heading, subheading, ctaLabel, ctaLink, media, content } = props;

  const mySwiper = useSwiper();
  const { currentPage, getToPage } = mySwiper;

  const nextCard = () => {
    getToPage((currentPage + 1) % media?.length);
  };

  const previusCard = () => {
    getToPage((currentPage - 1 + media?.length) % media?.length);
  };

  return (
    <ContentLayout bgColorClass="bg-white">
      <div className="flex flex-col gap-2">
        {label && (
          <AppText type={"LABEL_LARGE"} className="text-royal-blue">
            {label}
          </AppText>
        )}

        <div className="lg:flex-row lg:gap-10 flex flex-col justify-between gap-8">
          <div className="flex flex-col gap-4 max-w-[736px]">
            <AppText type={"HEADLINE_LARGE"}>{heading}</AppText>
            <AppText type="SUB_HEADING_MEDIUM" className={"mb-4 font-bold"}>
              {subheading}
            </AppText>

            <RichTextRenderer richTextDocument={content} id={"rtp"} locations className={"text-sm"} />
          </div>

          <AppButton link={ctaLink} intent={"secondary"} label={ctaLabel || ""} />
        </div>
      </div>
      <div className="lg:grid-cols-12 grid grid-cols-4 gap-5">
        <div className="lg:col-span-9 flex justify-center col-span-4 gap-6 overflow-hidden">
          <div className="relative overflow-hidden">
            <Carousel
              swiperData={mySwiper}
              className={cn("")} // flex-1 flex items-stretch w-screen relative left-1/2 -translate-x-1/2
              loop={false}
              slidesPerView={1}
              slidesPerGroup={1}
              spaceBetween={10}
              pagination={true}
              modules={[]}
              centeredSlides={true}
              rewind={true}
              watchOverflow={true}
              watchSlidesProgress={true}
              paginationType={"arrowSlider"}
              withoutWrapper={false}
            >
              {(media || []).map((item, idx) =>
                renderImage(
                  item?.thumbnails?.transformBaseUrl || "/placeholders/no-preview.png",
                  item?.description || "image",
                  idx
                )
              )}
            </Carousel>
          </div>
        </div>
        <div className="lg:col-span-3 lg:flex-col lg:grid-cols-1 sm:grid-cols-3 grid grid-cols-3 col-span-4 gap-6">
          {[1, 2, 3].map((index, idx) => {
            // We only have 3 cards always
            const rotatedIndex = (currentPage + media?.length - 2 + idx) % media?.length;
            const item = media?.[rotatedIndex];
            return (
              <div
                className={`col-span-1 flex ${
                  index === 1 ? "justify-start" : index === 2 ? "justify-center" : "justify-end"
                }`}
                key={idx}
              >
                <Image
                  sizes="100vw"
                  src={item?.thumbnails?.transformBaseUrl ?? "/placeholders/no-preview.png"}
                  width={250}
                  height={200}
                  className={cn(
                    `group-hover:scale-105 object-cover w-full transition aspect-[1.7] cursor-pointer ${
                      index === 2 ? "border-2 border-sky-600" : ""
                    }`
                  )}
                  alt={item?.description || "Media"}
                  onClick={() => {
                    if (index === 1) {
                      previusCard();
                    }
                    if (index === 3) {
                      nextCard();
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </ContentLayout>
  );
};

export default React.memo(ProductDetailsHeaderSection);
