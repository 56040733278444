/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";
import React, { useEffect, useRef, useState } from "react";
import AppText from "@/components/AppText";
import Image from "next/image";
import { cn } from "@/lib/utils";
import Carousel from "@/components/Carousel";
import { SwiperSlide } from "swiper/react";
import { useMediaQuery } from "@/hooks/mediaQuery";
import RichTextRenderer from "./RichTextRenderer";
import { BODY_SMALL } from "@/styles";

interface Step {
  id: string;
  heading: string;
  subheading: any;
  image: string;
  imgAlt: string;
}
interface StepByStepProps {
  id: string;
  steps: Step[];
}

const StepByStep: React.FC<StepByStepProps> = ({ steps, id }) => {
  const duration = 500;
  const divRef = useRef<HTMLDivElement>(null);
  const [startAnimation, setStartAnimation] = useState(false);
  const timeoutRefs = useRef<NodeJS.Timeout[]>([]);
  const [stepsAnimation, setStepsAnimation] = useState<boolean[]>(Array.from(Array(steps.length), () => false));
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isTablet = useMediaQuery("(min-width: 640px)");
  const activeStepAnimation = `transition-opacity duration-500 opacity-100`;
  const inactiveStepAnimation = "opacity-0";

  useEffect(() => {
    if (startAnimation) {
      return;
    }
    const handleScroll = () => {
      if (divRef.current && divRef.current?.getBoundingClientRect()?.top < 500) {
        setStartAnimation(true);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [startAnimation]);

  useEffect(() => {
    if (!startAnimation) {
      return;
    }
    stepsAnimation.forEach((step, index) => {
      timeoutRefs.current.push(
        setTimeout(() => {
          setStepsAnimation((prevState) => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
        }, index * duration)
      );
    });

    return () => {
      timeoutRefs.current.forEach(clearTimeout);
      timeoutRefs.current.length = 0;
    };
  }, [startAnimation]);

  const renderStep = (step: Step, index: number) => {
    const animate = stepsAnimation[index];
    const stepIndex = index + 1;
    return (
      <SwiperSlide key={step.id}>
        <div
          className={cn(
            "relative overflow flex flex-col h-full",
            animate ? activeStepAnimation : inactiveStepAnimation
          )}
        >
          <div className={"h-[1px] w-full bg-white absolute z-0 top-[14px] left-0"} />
          <div className={"h-[1px] w-[24px] bg-white absolute z-0 top-[14px] right-[-24px]"} />

          <AppText
            type={"BODY_MEDIUM_EMPHASIS"}
            className={
              "relative z-20 h-[28px] w-[28px] rounded-full bg-mellow flex flex-col items-center justify-center"
            }
          >
            {stepIndex}
          </AppText>

          <div className={"flex flex-col mt-[24px] flex-grow"}>
            <AppText type="SUB_HEADING_MEDIUM" className={"text-white"}>
              {step.heading}
            </AppText>

            <RichTextRenderer
              richTextDocument={step.subheading}
              id={`rt-${id}-${stepIndex}`}
              locations
              className={cn(BODY_SMALL, "text-royal-blue-20")}
            />
          </div>

          <div className="mt-6 relative w-[223px] 2xl:w-[250px] 2xl:h-[305px] h-[272px]">
            <Image src={step.image} alt={step.imgAlt} fill sizes="350px" className="object-cover" />
          </div>
        </div>
      </SwiperSlide>
    );
  };

  const maxSlidesOnScreen = isDesktop ? 4 : isTablet ? 3 : 2;
  const animateFirst = stepsAnimation[0];
  const lastVisibleSlideIndex = Math.min(maxSlidesOnScreen - 1, steps.length - 1);
  const animateLast = stepsAnimation[lastVisibleSlideIndex];

  return (
    <div className="bg-royal-blue relative">
      <div
        className={cn(
          "absolute h-[1px] w-[50%] bg-white z-100 top-[14px] left-0",
          animateFirst ? activeStepAnimation : inactiveStepAnimation
        )}
      />

      <div
        className={cn(
          "absolute h-[1px] w-[50%] bg-white  z-100 top-[14px] right-0",
          animateLast ? activeStepAnimation : inactiveStepAnimation
        )}
      />
      <div
        id={id}
        ref={divRef}
        className={cn(
          "relative flex pb-[80px] 2xl:px-[104px] xl:px-20 lg:px-8 pl-4 bg-royal-blue xl:max-w-screen-2xl w-full mx-auto"
        )}
      >
        <div
          className={cn(
            "absolute h-[1px] w-[150px] bg-white z-100 top-[14px]  left-0",
            animateFirst ? activeStepAnimation : inactiveStepAnimation
          )}
        />
        <div
          className={cn(
            "absolute h-[1px] w-full bg-white  z-100 top-[14px]  right-0",
            animateLast ? activeStepAnimation : inactiveStepAnimation
          )}
        />
        <Carousel
          withoutWrapper={true}
          className={"flex-1 flex items-stretch"}
          slidesPerView={1}
          spaceBetween={24}
          cardsOverflow={false}
          paginationType={"none"}
          breakpoints={{
            320: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
            475: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            640: {
              slidesPerView: 2.5,
              spaceBetween: 24,
            },
            786: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            900: {
              slidesPerView: 3.5,
              spaceBetween: 24,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 24,
            },
          }}
        >
          {steps.map(renderStep)}
        </Carousel>
      </div>
    </div>
  );
};

export default React.memo(StepByStep);
