"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import AppText from "@/components/AppText";
import ContentLayout from "@/components/layouts/ContentLayout";
import RichTextRenderer from "./RichTextRenderer";
import CustomLink from "./CustomLink";
import Image from "next/image";

interface IThankYouPage {
  id: string;
  heading: string;
  subheading: string;
  cardSubheading: string;
  cardHeading: string;
  cardContent: any;
  cards: ICard[];
}

interface ICard {
  id: string;
  heading: string;
  subheading: string;
  ctaLink: string;
  icon: string;
  image: string;
  icoAlt: string;
  imgAlt: string;
}

const SubmittedPage = ({ id, heading, subheading, cardSubheading, cardHeading, cardContent, cards }: IThankYouPage) => {
  return (
    <>
      <ContentLayout bgColorClass="bg-royal-blue" innerClass="flex flex-col items-center gap-4" id={id}>
        <AppText type="HEADLINE_LARGE" className="text-center text-white">
          {heading}
        </AppText>
        <AppText type="SUB_HEADING_SMALL" className="text-white text-center max-w-[736px]">
          {subheading}
        </AppText>
      </ContentLayout>

      <div className="bg-black-5 relative flex justify-center w-full">
        <div className="absolute h-[calc(50%-40px)] bg-royal-blue w-full z-1"></div>
        <div
          className={`
            w-full mx-auto xl:max-w-screen-2xl lg:max-w-screen-xl sm:max-w-screen-md md:max-w-screen-lg
            px-4 lg:px-8 xl:px-[80px] 2xl:px-[104px] pb-20 z-20
            grid-cols-4 lg:grid-cols-12 grid gap-y-2 sm:gap-y-6 xl:gap-y-[40px]  
            `}
        >
          <div
            className="lg:col-span-10 lg:col-start-2 col-span-4 p-8 gap-10 py-[56px] sm:px-6 lg:px-10 px-4
          bg-white flex flex-col lg:flex-row"
          >
            <div className="flex flex-col justify-center flex-1 gap-4">
              <AppText type="HEADLINE_SMALL">{cardHeading}</AppText>
              <AppText type="SUB_HEADING_SMALL">{cardSubheading}</AppText>

              <RichTextRenderer richTextDocument={cardContent} id={`rt-${id}`} locations />
            </div>

            <div className=" flex flex-col items-stretch flex-1 gap-6">
              {/* <SubmittedPageClientComponent mock={mock} lang={lang} dict={dict} /> */}

              {cards.map((card: ICard, idx: number) => {
                const isChatItem = card.ctaLink === "{{chat}}";
                // const isPhoneItem = card.ctaLink.startsWith('tel:');
                const cardLink = isChatItem ? undefined : card.ctaLink;
                const onClickHandler = isChatItem
                  ? (e: any) => {
                      e.preventDefault();
                      // Click div with id "liveagent_button_online_5738c000000spv5" to start chat // It will cause a popup
                      // window?.liveagent?.startChat(process.env.NEXT_PUBLIC_CHAT_ID ?? "5738c000000spv5"); // It will cause a popup
                      // This hack will open the chat in the tab
                      console.log(`Opening: Chat`);
                      const button = document.querySelector(".helpButtonEnabled") as HTMLButtonElement;
                      button?.click();
                    }
                  : undefined;

                return (
                  <CustomLink
                    key={idx}
                    href={cardLink || ""}
                    className="bg-navy-blue-10 grid grid-cols-8"
                    onClick={onClickHandler}
                  >
                    <div className="sm:col-span-3 col-span-8">
                      <Image
                        src={card.image}
                        alt={card.imgAlt}
                        sizes="100vw"
                        width={0}
                        height={0}
                        priority
                        className="object-cover w-full h-full min-h-[120px]"
                      />
                    </div>

                    <div className="sm:col-span-5 flex flex-col justify-center w-full col-span-8 gap-2 p-4">
                      <div className="flex flex-row items-center justify-between">
                        <AppText type="BODY_SMALL_EMPHASIS">{card.heading}</AppText>
                        <Image
                          src={card.icon}
                          alt={card.icoAlt}
                          sizes="100vw"
                          width={0}
                          height={0}
                          priority
                          className="object-cover w-6 h-6"
                        />
                      </div>
                      <AppText type="BODY_MEDIUM">{card.subheading}</AppText>
                    </div>
                  </CustomLink>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubmittedPage;
