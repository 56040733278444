"use client";

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";

import Image from "next/image";
import CustomLink from "@/components/CustomLink";
import { AppIconButton } from "./buttons/AppIconButton";
import { ChevronRight } from "lucide-react";
import { TITLE_SMALL } from "@/styles";
import { cn } from "@/lib/utils";
import { NO_PREVIEW_PLACEHOLDER } from "@/contentful/constants";

interface ICard {
  id: string;
  heading: string;
  subheading: string;
  ctaLink: string;
  ctaLabel: string;
  imgSrc: string;
  placeholder: string;
  imgAlt: string;
}
interface ProductCategoryOurSolutionsSectionProps {
  label: string;
  heading: string;
  subheading: string;
  cards: ICard[];
}

const ProductCategoryOurSolutionsSection: React.FC<ProductCategoryOurSolutionsSectionProps> = ({
  label,
  heading,
  subheading,
  cards,
}) => {
  return (
    <>
      <ContentLayout bgColorClass="bg-black-5">
        <div className="">
          <div className="flex flex-col mb-12">
            {label && (
              <AppText type={"LABEL_LARGE"} className="text-royal-blue mb-2">
                {label}
              </AppText>
            )}
            {heading && (
              <AppText type="HEADLINE_SMALL" className="mb-4">
                {heading}
              </AppText>
            )}
            {subheading && (
              <AppText type={"BODY_SMALL"} className="mb-4">
                {subheading}
              </AppText>
            )}
          </div>

          <div className="grid grid-cols-8 gap-6">
            {(cards || []).map((card) => (
              <SolutionCard {...card} key={card.id} />
            ))}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default React.memo(ProductCategoryOurSolutionsSection);

interface ISolutionCard {
  ctaLabel: string;
  ctaLink: string;
  heading?: string;
  id: string;
  imgSrc: string;
  placeholder: string;
  imgAlt: string;
  subheading: string;
}
const SolutionCard = ({ ctaLabel, ctaLink, id, imgSrc, imgAlt, subheading, placeholder }: ISolutionCard) => {
  return (
    <CustomLink
      href={ctaLink}
      key={`card-product-${id}`}
      className="bg-white group flex flex-col sm:col-span-4 col-span-8 lg:col-span-2 gap-2 p-4 rounded-[3px]"
    >
      <div className="relative overflow-hidden aspect-[16/9]">
        <Image
          src={imgSrc}
          sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 320px"
          fill
          placeholder="blur"
          blurDataURL={placeholder || NO_PREVIEW_PLACEHOLDER}
          className="group-hover:scale-105 object-contain w-full transition"
          alt={imgAlt}
        />
      </div>

      <AppIconButton
        intent="ghost"
        iconOnRightSide
        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
        label={ctaLabel}
        className={cn(TITLE_SMALL, "whitespace-normal text-start")}
      />
      <AppText type={"BODY_MEDIUM"}>{subheading}</AppText>
    </CustomLink>
  );
};
