/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import * as z from "zod";
import { OutputFormat, fromAddress, setDefaults } from "react-geocode";

import AppText from "@/components/AppText";
import IRequestServiceForm from "./RequestServiceForm";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input /* PhoneNumberInput */ } from "@/components/ui/input";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { SelectServiceSchema } from "./SelectServiceForm";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Button as CNButton } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { AppButton } from "@/components/buttons/AppButton";
import { APIProvider } from "@vis.gl/react-google-maps";
import SiteInput from "./SiteInput";
import { GeocodingResponse } from "../request-quote/constants";
import { toast } from "sonner";
import CustomFormLabel from "@/components/CustomFormLabel";
import { CustomFormMessage } from "@/components/CustomFormMessage";

let addressInterval: any;

setDefaults({
  key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!,
  language: "en",
  outputFormat: OutputFormat.JSON,
});

const additionalInformationSchema_Service = z.object({
  Contract_Number__c: z.string().min(1, { message: "required" }).max(255, { message: "max255chars" }),
  units: z.array(z.string()).min(1, { message: "required" }).max(255, { message: "max255chars" }),
  Explanation_of_Problem__c: z.string().min(1, { message: "required" }),
  Additional_Information__c: z.string().optional(),
});

const additionalInformationSchema_Relocation = z.object({
  Contract_Number__c: z.string().min(1, { message: "required" }).max(255, { message: "max255chars" }),
  units: z.array(z.string()).min(1, { message: "required" }).max(255, { message: "max255chars" }),
  New_Site_Contact__c: z.string().min(1, {
    message: "nameRequired",
  }),
  New_Site_Contact_Email__c: z.string().email({
    message: "invalidEmail",
  }),
  New_Site_Contact_Phone__c: z.string().min(1, {
    message: "phoneNumberRequired",
  }),
  New_Site_Country__c: z.string().min(1, {
    message: "countryRequired",
  }),
  New_Site_State__c: z.string().min(1, {
    message: "stateRequired",
  }),
  New_Site_Zip__c: z.string().min(1, {
    message: "zipcodeRequired",
  }),
  New_Site_City__c: z.string().min(1, {
    message: "cityRequired",
  }),
  New_Site_Address__c: z.string().min(1, {
    message: "addressRequired",
  }),
  Security_Clearance_Required_New__c: z.string().min(1, {
    message: "securityClearanceRequired",
  }),
  New_Site_Conditions__c: z.string().min(1, {
    message: "newSiteConditionRequired",
  }),
  Additional_Information__c: z.string().optional(),
  googlePlaces: z.string().min(1, { message: "required" }),
});

const additionalInformationSchema_Return = z.object({
  Contract_Number__c: z.string().min(1, { message: "required" }).max(255, { message: "max255chars" }),
  units: z.array(z.string()).min(1, { message: "required" }).max(255, { message: "max255chars" }),
  prefDate: z.date(),
  Additional_Information__c: z.string().optional(),
});

const additionalInformationSchema_Invoice = z.object({
  Contract_Number__c: z.string().min(1, { message: "required" }).max(255, { message: "max255chars" }),
  Inovice_Number__c: z.string().max(255, { message: "max255chars" }).optional(),
  Additional_Information__c: z.string().optional(),
});

const additionalInformationSchema_APInformation = z.object({
  Contract_Number__c: z.string().min(1, { message: "required" }).max(255, { message: "max255chars" }),
  Additional_Information__c: z.string().optional(),
});

const additionalInformationSchema_LeaseExtension = z.object({
  Contract_Number__c: z.string().min(1, { message: "required" }).max(255, { message: "max255chars" }),
  Requested_Lease_Extension_Date__c: z.date(),
  Additional_Information__c: z.string().optional(),
});

const serviceToSchemaMap = {
  Service: additionalInformationSchema_Service,
  Relocation: additionalInformationSchema_Relocation,
  Return: additionalInformationSchema_Return,
  Invoice: additionalInformationSchema_Invoice,
  "AP Information": additionalInformationSchema_APInformation,
  "Lease Extension": additionalInformationSchema_LeaseExtension,
};
type ServiceType = keyof typeof serviceToSchemaMap;

export type AdditionalInformationSchema = z.infer<
  typeof additionalInformationSchema_Service &
    typeof additionalInformationSchema_Relocation &
    typeof additionalInformationSchema_Return &
    typeof additionalInformationSchema_Invoice &
    typeof additionalInformationSchema_APInformation &
    typeof additionalInformationSchema_LeaseExtension
>;

const AdditionalInfoForm: React.FC<IRequestServiceForm> = ({
  onSubmitSuccess,
  onPrevHandler,
  formData,
  submitting,
  formCopy,
}) => {
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: 1 } | Record<string, never>>({});
  const errorMessageShown = useRef(false);
  const serviceType = (formData as SelectServiceSchema)?.Request_Type__c as ServiceType;
  const additionalInformationSchema = serviceToSchemaMap[serviceType];
  const form = useForm<z.infer<typeof additionalInformationSchema>>({
    resolver: zodResolver(additionalInformationSchema),
    values: formData as AdditionalInformationSchema,
    defaultValues: {
      Contract_Number__c: "",
      Inovice_Number__c: "",
      units: [],
      Explanation_of_Problem__c: "",
      prefDate: undefined, // new Date(),
      New_Site_Contact__c: "",
      New_Site_Contact_Email__c: "",
      New_Site_Contact_Phone__c: "",
      New_Site_Country__c: "",
      New_Site_State__c: "",
      New_Site_Zip__c: "",
      New_Site_City__c: "",
      New_Site_Address__c: "",
      Security_Clearance_Required_New__c: "",
      New_Site_Conditions__c: "",
      googlePlaces: "",
    },
    mode: "onBlur",
  });

  const oldFieldsValues: any = useRef(form.getValues());
  const watchAllFields: any = form.watch();
  let target: any = "";

  useEffect(() => {
    if (Object.keys(oldFieldsValues.current).length !== Object.keys(watchAllFields).length) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    for (target of Object.keys(watchAllFields)) {
      if (watchAllFields?.[target] !== oldFieldsValues?.current?.[target]) {
        form.trigger(target);
      }
    }

    oldFieldsValues.current = watchAllFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields]);

  useEffect(() => {
    clearInterval(addressInterval);
    if (selectedPlace) {
      const tmp = { ...errors };
      delete tmp.selectedPlace;
      setErrors(tmp);
      getSiteData();
      form.setValue("googlePlaces", "valid");
    } else {
      form.setValue("googlePlaces", "");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const data = (await fromAddress(selectedPlace?.formatted_address)) as GeocodingResponse;

      let city = "",
        state = "",
        zipCode = "",
        address = "",
        streetNumber = "",
        country = "";
      const components = data.results[0].address_components || [];

      for (const target of components) {
        const types = target.types || [];

        if (types.includes("locality")) {
          city = target.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          state = target.long_name;
          // const stateData = JSON.stringify({ long: target.long_name, short: target.short_name });
          // localStorage.setItem("siteState", stateData);
        } else if (types.includes("postal_code")) {
          zipCode = target.long_name;
        } else if (types.includes("route")) {
          //(types.includes("route") || types.includes("neighborhood"))

          address = target.long_name;
        } else if (types.includes("street_number")) {
          streetNumber = target.long_name;
        } else if (types.includes("country")) {
          country = target.long_name;
        }
      }

      form.setValue("New_Site_Country__c", country);
      form.setValue("New_Site_State__c", state);
      form.setValue("New_Site_Zip__c", zipCode);
      form.setValue("New_Site_City__c", city);
      form.setValue("New_Site_Address__c", `${streetNumber} ${address}`);

      form.trigger();
    }
  };

  const onSubmit = (values: z.infer<typeof additionalInformationSchema>) => {
    onSubmitSuccess(values);
  };

  const siteAddressBlur = () => {
    if (!selectedPlace) {
      setErrors((prev) => ({ ...prev, selectedPlace: 1 }));
    }

    addressInterval = setTimeout(() => {
      checkAfterBlur();
    }, 500);
  };

  const checkAfterBlur = () => {
    if (!selectedPlace) {
      if (!errorMessageShown.current) {
        toast.error("Please select place from suggestions list");
        errorMessageShown.current = true;
      }
      form.setValue("New_Site_Country__c", "");
      form.setValue("New_Site_State__c", "");
      form.setValue("New_Site_Zip__c", "");
      form.setValue("New_Site_City__c", "");
      form.setValue("New_Site_Address__c", "");
    }
  };

  const handleDeliveryAddressOnChange = () => {
    setSelectedPlace(null);
    errorMessageShown.current = false;
  };

  const handleFormSubmit = (): void => {
    const btn = document.getElementById("targetSubmitAdditional");

    btn && btn.click();
  };

  return (
    <Form {...form}>
      <form
        className="lg:col-span-10 lg:col-start-2 lg:px-10 pb-[56px] col-span-4  bg-white"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="lg:px-10 px-6 py-4">
          <AppText type="HEADLINE_SMALL" as="h2">
            {formCopy[serviceType]}
          </AppText>
        </div>
        <div className="pb-14 lg:px-10 flex flex-col gap-6 p-4 px-6">
          <AppText type="TITLE_MEDIUM">{formCopy["page-3-unit-information-title"]}</AppText>
          <div className="lg:grid-cols-2 grid grid-cols-1 gap-6">
            <FormField
              control={form.control}
              name="Contract_Number__c"
              render={({ field, fieldState: { error } }) => (
                <FormItem className="col-span-1 space-y-0">
                  <CustomFormLabel label={`${formCopy["quote-number-label"]}*`} />
                  <FormControl>
                    <Input placeholder={formCopy["quote-number-placeholder"]} {...field} aria-describedby={undefined} />
                  </FormControl>
                  {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                </FormItem>
              )}
            />
            <AppText className="lg:col-span-2" type="CAPTION_MEDIUM">
              {formCopy["quote-number-footnote"]}
            </AppText>

            {/** web-1886  */}
            {"Invoice" === serviceType && (
              <FormField
                control={form.control}
                name="Inovice_Number__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="col-span-1 space-y-0">
                    <CustomFormLabel label={`${formCopy.invoiceNumber}`} />
                    <FormControl>
                      <Input placeholder={formCopy.invoiceNumberPlaceholder} {...field} aria-describedby={undefined} />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            )}

            {["Service", "Relocation", "Return"].includes(serviceType) && (
              <>
                <FormField
                  control={form.control}
                  name="units"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem className="col-span-1 space-y-0">
                      <CustomFormLabel label={`${formCopy["unit-number-label"]}*`} />
                      <FormControl>
                        {/* TODO: In future we need to use multiple input field or multiple select field */}
                        <Input
                          value={(field.value && field.value[field.value.length - 1]) || ""}
                          onChange={(e) => {
                            field.onChange([e.target.value]);
                          }}
                          placeholder={formCopy["unit-number-placeholder"]}
                          name="units"
                          ref={field.ref}
                          aria-describedby={undefined}
                        />
                      </FormControl>
                      {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                    </FormItem>
                  )}
                />
                <div className="col-span-1"></div>
              </>
            )}
            {"Return" === serviceType && (
              <>
                <FormField
                  control={form.control}
                  name="prefDate"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem className="flex flex-col col-span-1 space-y-0">
                      <p
                        className={cn(
                          "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                          error && "text-red-500"
                        )}
                      >{`${formCopy.preferredPickUpDate}*`}</p>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <CNButton
                              aria-describedby={undefined}
                              variant={"outline"}
                              className={cn(
                                "pl-3 text-left font-normal !mt-0 border-black",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value ? (
                                moment(field.value).format("LL")
                              ) : (
                                <span className={cn(!field.value && "text-black-40")}>{formCopy.selectYourDate}</span>
                              )}
                              <CalendarIcon className="w-4 h-4 ml-auto opacity-50" aria-label="calendar icon" />
                            </CNButton>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) => date <= moment().subtract(1, "days").toDate()}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                    </FormItem>
                  )}
                />
              </>
            )}
            {"Lease Extension" === serviceType && (
              <FormField
                control={form.control}
                name="Requested_Lease_Extension_Date__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex flex-col col-span-1 space-y-0">
                    <p
                      className={cn(
                        "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                        error && "text-red-500"
                      )}
                    >{`${formCopy.requestLeaseExtensionDate}*`}</p>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <CNButton
                            aria-describedby={undefined}
                            variant={"outline"}
                            className={cn(
                              "pl-3 text-left font-normal !mt-0 border-black",
                              !field.value && "text-muted-foreground"
                            )}
                          >
                            {field.value ? (
                              moment(field.value).format("LL")
                            ) : (
                              <span className={cn(!field.value && "text-black-40")}>{formCopy.selectYourDate}</span>
                            )}
                            <CalendarIcon className="w-4 h-4 ml-auto opacity-50" aria-label="calendar icon" />
                          </CNButton>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          aria-describedby={undefined}
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) => date <= moment().subtract(1, "days").toDate()}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            )}
          </div>
        </div>
        {"Service" === serviceType && (
          <div className="pb-14 lg:px-10 flex flex-col gap-6 p-4 px-6">
            <AppText type="TITLE_MEDIUM">{formCopy["page-3-request-details-title"]}</AppText>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Explanation_of_Problem__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy.explanationOfTheProblem}*`} />
                    <FormControl>
                      <Textarea
                        className="min-h-[170px]"
                        placeholder={formCopy.requestDetailsTextareaPlaceholder}
                        {...field}
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>

            <div className="lg:flex-row flex flex-col gap-6"></div>
          </div>
        )}
        {"Relocation" === serviceType && (
          <div className="pb-14 lg:px-10 flex flex-col gap-6 p-4 px-6">
            <AppText type="TITLE_MEDIUM">{formCopy["page-3-request-details-title"]}</AppText>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="New_Site_Contact__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-name-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["new-site-contact-name-placeholder"]}
                        {...field}
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="New_Site_Contact_Email__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-email-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["new-site-contact-email-placeholder"]}
                        {...field}
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="New_Site_Contact_Phone__c"
                rules={{
                  validate: (value) =>
                    matchIsValidTel(value ?? "", {
                      onlyCountries: ["US", "CA"],
                    }),
                }}
                render={({ field: { value, ref, ...fieldProps }, fieldState: { error, invalid } }) => (
                  <FormItem className="flex flex-col flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-phone"]}*`} />
                    <div className="h-0 overflow-hidden">
                      <FormControl>
                        <Input
                          className="hiddenInput"
                          ref={ref}
                          tabIndex={-1}
                          autoComplete="nope"
                          aria-describedby={undefined}
                        />
                      </FormControl>
                    </div>
                    <div className="telInputCustom focus-within:ring-[2px] ring-offset-2  ring-blue-500/50 outline-none">
                      <FormControl>
                        <MuiTelInput
                          {...fieldProps}
                          aria-describedby={undefined}
                          inputProps={{ style: { padding: "0.5rem" } }}
                          defaultCountry={"US"}
                          onlyCountries={["US", "CA"]}
                          value={value ?? ""}
                          error={invalid ? true : false}
                        />
                      </FormControl>
                    </div>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!}>
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name="googlePlaces"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormItem className="flex-1 space-y-0">
                          <div className="h-0 overflow-hidden">
                            <FormControl>
                              <Input
                                {...field}
                                className="hiddenInput"
                                tabIndex={-1}
                                autoComplete="nope"
                                role="presentation"
                                aria-describedby={undefined}
                              />
                            </FormControl>
                          </div>

                          <SiteInput
                            label={`${formCopy["new-site-contact-address-label"]}*`}
                            setSelectedPlace={setSelectedPlace}
                            deliveryAddressBlur={siteAddressBlur}
                            error={!!errors?.selectedPlace}
                            handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                            placeholder={formCopy["new-site-contact-address-placeholder"]}
                          />
                          {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </APIProvider>
              <div className="flex-1"></div>
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="New_Site_State__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-state-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["new-site-contact-state-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        type="inputText"
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="New_Site_Zip__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-zip-code-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["new-site-contact-zip-code-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        type="inputText"
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="New_Site_City__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-city-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["new-site-contact-city-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        type="inputText"
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="New_Site_Country__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <CustomFormLabel label={`${formCopy["new-site-contact-country-label"]}*`} />
                    <FormControl>
                      <Input
                        placeholder={formCopy["new-site-contact-country-placeholder"]}
                        {...field}
                        autoComplete={"off"}
                        type="inputText"
                        role="presentation"
                        aria-describedby={undefined}
                      />
                    </FormControl>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
            <div className="lg:flex-row flex flex-col gap-6">
              <FormField
                control={form.control}
                name="Security_Clearance_Required_New__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <p
                      className={cn(
                        "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                        error && "text-red-500"
                      )}
                    >{`${formCopy["new-site-contact-security-clearence-label"]}*`}</p>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger
                          className="!mt-0 border-black"
                          ref={field.ref}
                          title={formCopy["new-site-contact-security-clearence-placeholder"]}
                          aria-describedby={undefined}
                        >
                          <SelectValue placeholder={formCopy["new-site-contact-security-clearence-placeholder"]} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {[
                          "new-site-contact-security-clearence-option-1",
                          "new-site-contact-security-clearence-option-2",
                        ]
                          .map((option: string) => formCopy[option])
                          .map((option, idx) => (
                            <SelectItem key={idx} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="New_Site_Conditions__c"
                render={({ field, fieldState: { error } }) => (
                  <FormItem className="flex-1 space-y-0">
                    <p
                      className={cn(
                        "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                        error && "text-red-500"
                      )}
                    >{`${formCopy["new-site-contact-site-condition-label"]}*`}</p>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger
                          className="!mt-0 border-black"
                          ref={field.ref}
                          title={formCopy["new-site-contact-security-clearence-placeholder"]}
                          aria-describedby={undefined}
                        >
                          <SelectValue placeholder={formCopy["new-site-contact-security-clearence-placeholder"]} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {[
                          "new-site-contact-site-condition-option-1",
                          "new-site-contact-site-condition-option-2",
                          "new-site-contact-site-condition-option-3",
                        ]
                          .map((option: string) => formCopy[option])
                          .map((option, idx) => (
                            <SelectItem key={idx} value={option}>
                              {option}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                    {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
                  </FormItem>
                )}
              />
            </div>
          </div>
        )}
        <div className="pb-14 lg:px-10 flex flex-col gap-6 p-4 px-6">
          <FormField
            control={form.control}
            name="Additional_Information__c"
            render={({ field, fieldState: { error } }) => (
              <FormItem className="lg:col-span-2 space-y-0">
                <CustomFormLabel label={`${formCopy["new-site-contact-additional-info-label"]}`} />
                <FormControl>
                  <Textarea
                    className="min-h-[170px]"
                    placeholder={formCopy["new-site-contact-additional-info-placeholder"]}
                    {...field}
                    aria-describedby={undefined}
                  />
                </FormControl>
                {error?.message && <CustomFormMessage>{formCopy[error.message]}</CustomFormMessage>}
              </FormItem>
            )}
          />
        </div>
        <div className="bg-white flex flex-row-reverse flex-wrap justify-between px-[40px]">
          {submitting ? (
            <button
              id="button-request-quote"
              className={`bg-black-20 text-black-60 px-10 disabled:cursor-not-allowed py-2
               transition-all duration-200 rounded-md outline-none gap-3 w-fit select-none flex items-center`}
              disabled
            >
              <div
                className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
              ></div>
              <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                {formCopy.submitting}
              </AppText>
            </button>
          ) : (
            <>
              <button id="targetSubmitAdditional" className="hidden" type="submit" aria-label="submit target"></button>
              <AppButton
                intent="primary"
                label={formCopy.submit}
                type="submit"
                className="px-10"
                onMouseDown={handleFormSubmit}
              />
            </>
          )}
          <AppButton
            intent="secondary"
            label={formCopy["previous-cta"]}
            className="px-10"
            onMouseDown={onPrevHandler}
            // onClick={onPrevHandler}
          />
        </div>
      </form>
    </Form>
  );
};

export default AdditionalInfoForm;
