/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyboardEvent, useRef } from "react";
import { ChevronRight, X } from "lucide-react";

import AppText from "./AppText";
import { AppIconButton } from "./buttons/AppIconButton";

import { cn } from "@/lib/utils";

export const WorkspaceDot = ({ visibleTooltip, setVisibleTooltip, idx, dot, isMobile, closeTooltip }: any) => {
  const dotRef = useRef<HTMLDivElement>(null);

  const handleDotClick = () => {
    if (dotRef.current) {
      const { left } = dotRef.current.getBoundingClientRect();

      setVisibleTooltip({ idx, isLeft: left > 326 });
    }
  };

  const handleDotKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      handleDotClick();
    }
  };

  return (
    <>
      <div
        tabIndex={0}
        key={dot.id}
        ref={dotRef}
        onClick={handleDotClick}
        onKeyDown={handleDotKeyDown}
        style={{
          top: `${dot.top < 100 ? dot.top : 99}%`,
          left: `${dot.left < 100 ? dot.left : 99}%`,
        }}
        className={`ring-1 focus:ring-dijon ring-offset-1 ring-offset-transparent 
        ring-transparent absolute w-6 h-6 overflow-visible rounded-full outline-none`}
      >
        <div
          className={`bg-[#F8DD75] z-9 animation-pulse fade-in absolute 
        top-0 left-0 w-6 h-6 rounded-full cursor-pointer`}
        />

        {visibleTooltip && visibleTooltip.idx === idx && (
          <Tooltip
            ctaLink={dot.info.ctaLink}
            read_more={dot.info.ctaLabel}
            subheading={dot.info.subheading}
            isLeft={visibleTooltip.isLeft}
            isMobile={isMobile}
            heading={dot.info.heading}
            handleTooltipClose={() => closeTooltip()}
            // label={dot.info.label}
          />
        )}
      </div>
    </>
  );
};

const Tooltip = ({
  ctaLink,
  read_more,
  subheading,
  heading,
  // label,
  handleTooltipClose,
  isMobile,
  isLeft,
}: any) => (
  <div
    className={cn(
      `backdrop-blur-[6px] bg-[#FFFFFFCC] cursor-default flex-col z-50
      border-l-[1px] border-navy-blue pl-8 pt-4 pr-4 pb-2 
      w-max md:max-w-[326px] max-w-[310px] 
      shadow-[10px_10px_34px_-17px_rgba(0,0,0,0.75)]`,
      isMobile
        ? "fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
        : isLeft
        ? "absolute animate-in fade-in duration-500 -top-[80px] -left-[336px] -translate-x-[10px]"
        : "absolute animate-in fade-in duration-500 -top-[80px] left-[30px] translate-x-[10px]"
    )}
  >
    <div className="flex justify-between">
      {/* <AppText type={"LABEL_LARGE"} className={"text-royal-blue mb-1"}>
        {label}
      </AppText> */}

      {isMobile && (
        <button
          id="button-workspace-dot"
          className="hover:bg-black-40 p-[2px] transition outline-none focus:outline-none ring-1 ring-transparent focus:ring-dijon  "
          onClick={(e) => {
            e.stopPropagation();
            handleTooltipClose();
          }}
        >
          <X size={16} aria-label="x icon" />
        </button>
      )}
    </div>

    <AppText type={"BODY_MEDIUM_EMPHASIS"} className="mb-2">
      {heading}
    </AppText>

    <AppText type={"BODY_SMALL"} className="mb-2">
      {subheading}
    </AppText>

    {ctaLink && (
      <AppIconButton
        intent="ghost"
        label={read_more}
        link={ctaLink}
        icon={<ChevronRight size={20} aria-label="chevron right icon" />}
        iconOnRightSide
      />
    )}
  </div>
);
