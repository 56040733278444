/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React from "react";
import ContentLayout from "./layouts/ContentLayout";
import CustomLink from "./CustomLink";
import { useLocale } from "@/store/clientStore";
import Image from "next/image";
import AppText from "./AppText";
import { AppIconButton } from "./buttons/AppIconButton";

import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";

interface ISubIndustriesList {
  id: string;
  subIndustries: ISubIndustry[] | [];
  readMore?: string;
  categorySlug?: string;
}

interface ISubIndustry {
  id: string;
  image: string;
  placeholder: string;
  imgAlt: string;
  slug: string;
  title: string;
  ctaLabel?: string;
}

const SubIndustriesList = ({ id, subIndustries, readMore, categorySlug }: ISubIndustriesList) => {
  const { locale } = useLocale();

  const widerCards = subIndustries.length > 3;

  return (
    <ContentLayout id={id}>
      <div className="lg:grid-cols-12 sm:gap-6 xl:gap-10 grid grid-cols-4 gap-2">
        {(subIndustries || []).map((item: ISubIndustry) => (
          <CustomLink
            lang={locale}
            key={item.id}
            href={categorySlug ? `/industries/${categorySlug}/${item.slug}` : item.slug}
            className={cn("col-span-4 sm:col-span-2 group", widerCards ? "lg:col-span-3" : "lg:col-span-4")}
          >
            <div className="relative mb-4 overflow-hidden  aspect-[16/9]">
              <Image
                src={item.image}
                alt={item.imgAlt}
                sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 450px"
                fill
                placeholder="blur"
                blurDataURL={item.placeholder}
                className="group-hover:scale-105 object-cover w-full transition"
              />
            </div>
            <AppText type="SUB_HEADING_SMALL" className="mb-2">
              {item.title}
            </AppText>
            <AppIconButton
              tabIndex={-1}
              intent="ghost"
              iconOnRightSide
              icon={<ChevronRight size={20} aria-label="chevron right icon" />}
              label={item.ctaLabel || readMore || "Read more"}
              className="after:left-0 after:right-0 px-0"
            />
          </CustomLink>
        ))}
      </div>
    </ContentLayout>
  );
};

export default SubIndustriesList;
