// import { getPlaceholderImage } from "./getPlaceholderImage";

/* eslint-disable @typescript-eslint/no-explicit-any */
export const esgMapping = (data: any, read_more: string) =>
  (data.items || []).map((item: any) => {
    const img = item.fields?.featuredImage?.[0]?.thumbnails?.transformBaseUrl;

    return {
      id: item.sys?.id,
      name: item.fields?.name,
      ctaLabel: read_more,
      slug: `/about-us/sustainability-impact/sustainability/${item.fields?.slug}`,
      category: item.fields?.category,
      featuredImage: img ?? "/placeholders/no-preview.png",
      // placeholder: await getPlaceholderImage(img ?? ""),
      imgAlt: item.fields?.featuredImage?.[0]?.description || "image",
    };
  });
