/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";
import Image from "next/image";

import ContentLayout from "./layouts/ContentLayout";
import { colorsMap, fallbackColors } from "./constants";
import AppText from "./AppText";
import { cn } from "@/lib/utils";
import { AppButton } from "./buttons/AppButton";
import { AppIconButton } from "./buttons/AppIconButton";
import CustomLink from "./CustomLink";
import { useLocale } from "@/store/clientStore";
import { Select, SelectContent, SelectItem, SelectTrigger } from "./ui/select";
import { ChevronRight } from "lucide-react";

interface ICustomizationList {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  ctaTheme: "primary" | "secondary" | "ghost" | undefined;
  background: string;
  customizationTabs: ITab[];
  findOutMore: string;
}

interface ITab {
  id: string;
  heading: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  customizations: any[];
  customizationId: string;
  title: string;
  categorySlug: string;
}

interface IMobileDropdown {
  tabs: ITab[];
  handleTabClick: (id: string) => void;
  activeTab: ITab;
}

const CustomizationList = ({
  id,
  label,
  heading,
  ctaLabel,
  ctaTheme,
  background,
  customizationTabs,
  findOutMore,
  subheading,
}: ICustomizationList) => {
  const [activeTab, setActiveTab] = useState(customizationTabs[0]);
  const { locale } = useLocale();

  const { labelColor, textColor, bgColorClass, bg } = colorsMap[background] ?? fallbackColors;

  const handleTabClick = (id: string) => {
    const newTab = (customizationTabs || []).find((tab: ITab) => tab.id === id);

    newTab && setActiveTab(newTab);
  };

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id}>
      {label && (
        <AppText type="LABEL_LARGE" className={cn("mb-2", labelColor)}>
          {label}
        </AppText>
      )}

      <div className="lg:flex-row flex flex-col justify-between gap-6 mb-6">
        <div className="max-w-[1036px]">
          <AppText type="HEADLINE_MEDIUM" className={cn("mb-4", textColor)}>
            {heading}
          </AppText>
          <AppText type="BODY_MEDIUM" className={cn(textColor)}>
            {subheading}
          </AppText>
        </div>

        {ctaLabel && (
          <AppButton
            label={ctaLabel}
            link="/customizations/overview"
            intent={ctaTheme || "secondary"}
            className={"max-lg:mt-[32px]"}
            bg={bg}
          />
        )}
      </div>

      <div className="lg:grid-cols-12 sm:gap-6 xl:gap-10 gap-y-6 grid grid-cols-4 gap-2">
        <div className="lg:hidden flex col-span-4">
          <MobileDropdown tabs={customizationTabs} handleTabClick={handleTabClick} activeTab={activeTab} />
        </div>

        <ul className="lg:flex flex-col hidden col-span-3 gap-2">
          {(customizationTabs || []).map((tab: ITab) => (
            <li
              key={tab.id}
              className={cn(
                "py-[6px] pl-6 border-l-[2px] border-transparent",
                tab.id === activeTab?.id && "border-mellow"
              )}
            >
              <button
                onClick={() => handleTabClick(tab.id)}
                className={cn(
                  `font-sairaSemiCondensed text-start text-16 font-[500] leading-[1.75]  text-[#F2F2F2] 
                  overflow-hidden w-full text-ellipsis`,
                  tab.id === activeTab?.id && "text-mellow"
                )}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>

        <div className="sm:gap-6 xl:gap-10 lg:col-span-9 col-span-4 gap-2">
          <div className="lg:grid-cols-9 sm:gap-6 xl:gap-10 grid grid-cols-4 gap-2 mb-3">
            <div className="lg:col-span-7 col-span-4">
              <AppText type="SUB_HEADING_SMALL" className={cn("mb-2", textColor)}>
                {activeTab.heading}
              </AppText>
              <AppText type="BODY_SMALL" className={cn(textColor)}>
                {activeTab.subheading}
              </AppText>
            </div>

            <div className="lg:justify-end lg:col-span-2 flex justify-start col-span-4">
              <AppIconButton
                intent="ghost"
                iconOnRightSide
                icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                label={activeTab.ctaLabel || `View ${activeTab.title}`}
                link={activeTab.ctaLink}
                bg={bg}
              />
            </div>
          </div>

          <div className="lg:grid-cols-9 gap-y-2 sm:gap-x-6 xl:gap-x-10 gap-x-2 grid grid-cols-4 mb-12">
            {(activeTab.customizations || []).map((item: any) => {
              return (
                <CustomLink
                  key={item.id}
                  href={`/customizations/${item.categorySlug}/${item.slug}`}
                  lang={locale || "en"}
                  className="lg:col-span-3 group sm:col-span-2 flex flex-col col-span-4"
                >
                  <div className="mb-2 overflow-hidden relative aspect-[16/9]">
                    <Image
                      src={item.image}
                      alt={item.imgAlt}
                      sizes="(max-width: 640px) 600px, (max-width: 1024px) 500px, 320px"
                      fill
                      className="group-hover:scale-105 object-cover w-full transition"
                      placeholder="blur"
                      blurDataURL={item.placeholder}
                    />
                  </div>

                  <AppText type="TITLE_X_SMALL" className={cn("mb-1", textColor)}>
                    {item.title}
                  </AppText>

                  <AppIconButton
                    tabIndex={-1}
                    intent="ghost"
                    iconOnRightSide
                    icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                    label={findOutMore}
                    bg={bg}
                    className="after:bg-white after:left-0 after:right-0 px-0 mt-auto"
                  />
                </CustomLink>
              );
            })}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};

export default CustomizationList;

const MobileDropdown = ({ tabs, handleTabClick, activeTab }: IMobileDropdown) => {
  return (
    <Select defaultValue={activeTab.title} onValueChange={(value) => handleTabClick(value)}>
      <SelectTrigger
        value={activeTab.title}
        className={cn(
          "w-full border-t-0 border-l-0 border-r-0 border-b-mellow border-b-[2px] max-w-full rounded-none font-saira font-[500] text-[16px] text-mellow bg-[#FCFCFC0D]"
        )}
      >
        {activeTab.title}
      </SelectTrigger>
      <SelectContent className="rounded-none">
        {(tabs || []).map((tab: ITab) => (
          <SelectItem value={tab.id} key={tab.id}>
            {tab.title}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
