"use client";

import React from "react";
import { SwiperSlide } from "swiper/react";
import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import Carousel from "@/components/Carousel";
import { Pagination, Navigation } from "swiper/modules";
import { AppIconButton } from "../buttons/AppIconButton";

import Image from "next/image";
import { ChevronRight } from "lucide-react";

interface ITestimonial {
  id: string;
  quote: string;
  image: string;
  imgAlt: string;
  ctaLabel: string;
  name: string;
  jobTitle: string;
  company: string;
  ctaLink: string;
}
interface ITestimonials {
  id: string;
  heading?: string;
  testimonials: ITestimonial[];
}

const Testimonials: React.FC<ITestimonials> = ({ heading, testimonials, id }) => {
  return (
    <ContentLayout bgColorClass="bg-navy-blue" innerClass="relative" id={id}>
      {heading && (
        <AppText type={"HEADLINE_MEDIUM"} className="flex justify-center text-white mb-[56px] lg:mb-[64px]">
          {heading}
        </AppText>
      )}

      <Carousel
        key={id}
        className={"flex-1  relative testimonialWrapper inline-flex"}
        loop={true}
        slidesPerView={1}
        spaceBetween={16}
        pagination={true}
        centeredSlides={true}
        rewind={true}
        watchOverflow={true}
        navigation={true}
        modules={[Navigation, Pagination]}
        paginationType={"arrowsNew"}
        bg="dark"
      >
        {testimonials.map(renderArticle)}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(Testimonials);

const renderArticle = (testimonial: ITestimonial) => {
  const { id: testimonialId, quote, image, ctaLabel, imgAlt, name, jobTitle, company, ctaLink } = testimonial;

  return (
    <SwiperSlide key={testimonialId}>
      <div className="bg-[#19366b] gap-10 p-12 flex flex-col md:flex-row mx-0 md:mx-[88px]">
        <div className="flex items-stretch flex-1">
          <AppText type={"SUB_HEADING_SMALL"} className="flex justify-center text-white">
            {quote}
          </AppText>
        </div>

        <div className="border-navy-blue-60 flex flex-col flex-1 gap-8 pl-6 md:pl-10 border-l-[1px]">
          <div className="lg:flex-row flex flex-col gap-8">
            {image && (
              <div className="overflow-hidden bg-white max-w-[130px]">
                <Image
                  src={image}
                  alt={imgAlt}
                  sizes="100vw"
                  width={0}
                  height={0}
                  className="object-cover w-full h-full max-w-[130px]"
                />
                {/* <Image   src={image} alt="" width={130} height={64} /> */}
              </div>
            )}

            <div className="flex flex-col">
              <AppText type={"BODY_SMALL_EMPHASIS"} className="flex text-white">
                {name}
              </AppText>

              <AppText type={"BODY_SMALL"} className="flex text-white">
                {jobTitle}
              </AppText>

              <AppText type={"BODY_SMALL"} className="text-black-10 flex">
                {company}
              </AppText>
            </div>
          </div>
          {ctaLabel && (
            <div>
              <AppIconButton
                iconOnRightSide
                intent="ghost"
                bg="dark"
                icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                link={ctaLink}
                label={ctaLabel}
                className="z-10"
              />
            </div>
          )}
        </div>
      </div>
    </SwiperSlide>
  );
};
