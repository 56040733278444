/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";

import { Select } from "../Select";
import Pagination from "../Pagination";
import { ITEMS_PER_PAGE } from "../constants";
import { Card } from "../cards/Card";
import ContentLayout from "../layouts/ContentLayout";
import { mockOutfittingPackagesList } from "@/mocks/indexListMocks";
import AppText from "../AppText";
import { getPlaceholderImage } from "@/lib/getPlaceholderImage";

interface ICaseStudy {
  id?: string;
  dict: { [key: string]: string };
  preview: boolean;
  industries: any;
  list: any;
  totalSum: number;
  storybook?: boolean;
  categoryId: string;
}

const OutfittingPackagesList: React.FC<ICaseStudy> = ({
  dict,
  preview,
  industries,
  list,
  totalSum,
  id,
  storybook,
  categoryId,
}) => {
  const [cards, setCards] = useState<any>(list);
  const [allIndustries] = useState<{ name: string; value: string }[] | null>(industries);
  const [selectedIndustry, setSelectedIndustry] = useState<any>(industries[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState<number>(totalSum);
  const [loading, setLoading] = useState(false);

  const doSequential = useRef(false);

  const lastPage = Math.ceil(total / ITEMS_PER_PAGE);

  useEffect(() => {
    setTimeout(() => {
      doSequential.current = true;
    }, 1000);
  }, []);

  useEffect(() => {
    if (!doSequential.current) return;

    if (storybook) {
      setTotal(mockOutfittingPackagesList.list.length);
      setCards(mockOutfittingPackagesList.list);
    } else {
      (async () => {
        try {
          setLoading(true);
          const associatedIndustrySysID = selectedIndustry.value === "allIndustries" ? "" : selectedIndustry.value;

          const res = await fetchContentful(
            preview,
            associatedIndustrySysID,
            currentPage - 1,
            dict.view_package,
            categoryId
          );

          if (res.error) {
            console.log(res.error || res.error.message);
            // ! throw toast?!
          }

          setTotal(res.total);
          setCards(res.cardsList);
        } catch (err) {
          console.log("Error occurred when fetching filters");
          console.log(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [currentPage, selectedIndustry, preview, dict, storybook, categoryId]);

  const popularIndustries = () => {
    const popular = allIndustries?.slice(1, 5);

    return (popular || []).map((pop) => (
      <button
        id="button-outfitting-packages-list"
        key={pop.value}
        onClick={() => setIndustry(pop.value)}
        className="px-4 py-[6px] rounded-[5px] border-[1px] border-slate-10 outline-none focus-visible:outline-dijon hover:bg-black-5"
      >
        <AppText type="BUTTON_SMALL">{pop.name}</AppText>
      </button>
    ));
  };

  const setIndustry = (value: string) => {
    const filtered = (allIndustries || []).find((ind: any) => ind.value === value);

    if (filtered && filtered.value !== selectedIndustry.value) {
      setSelectedIndustry(filtered);
    }
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id}>
      {!storybook && (
        <div className="sm:gap-6 flex flex-col gap-4 pb-8">
          <div className="sm:flex-row sm:gap-6 sm:items-center flex flex-col justify-between gap-4">
            <div className="w-full sm:max-w-[386px] ">
              <Select
                selectedItem={selectedIndustry}
                setSelected={setSelectedIndustry}
                options={industries}
                disabled={!allIndustries}
              />
            </div>
          </div>
          <div className="flex text-[14px]">
            {total > 0 && (
              <span>
                {total} {total === 1 ? dict.result : dict.results}
              </span>
            )}
          </div>
        </div>
      )}

      {loading ? (
        <>
          <div className="flex items-center">
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
            <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
          </div>
        </>
      ) : !cards.length && !total ? (
        <div className="flex flex-col">
          <AppText type="HEADLINE_SMALL" className="mb-4">
            {`${dict.sorryNoResults}  ${selectedIndustry.name} `}
          </AppText>
          <AppText type="BODY_MEDIUM" className="text-black-80 mb-14">
            {dict.tryDifferentIndustry}
          </AppText>
          <AppText type="TITLE_LARGE" className="mb-6">
            {dict.filterIndustries}
          </AppText>

          <div className="flex flex-wrap gap-2">{popularIndustries()}</div>
        </div>
      ) : (
        <div className="max-w-[1328px] mx-auto grid grid-cols-1 2xl:grid-cols-3 sm:grid-cols-2 gap-4 xl:gap-10 md:gap-6 ">
          {cards.map((study: any) => (
            <Card key={study.id} {...study} />
          ))}
        </div>
      )}

      {!loading && total > 12 && (
        <div className="flex justify-center px-6 py-[48px] mt-8">
          <Pagination currentPage={currentPage} lastPage={lastPage} setCurrentPage={setCurrentPage} />
        </div>
      )}
    </ContentLayout>
  );
};

export default OutfittingPackagesList;

const fetchContentful = async (
  preview: boolean,
  aiSysID: string,
  skip: number,
  view_package: string,
  categoryId: string
) => {
  const payload = {
    content_type: "solution",
    include: 10,
    skip: skip * 12,
    limit: 12,
    "fields.category.sys.id": categoryId,
    "fields.associatedIndustry.sys.id": aiSysID,
    select: ["sys.id", "fields.title", "fields.featuredImage", "fields.slug", "fields.excerpt"],
  };

  try {
    const data = await fetch("/api/contentful", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    const res = await data.json();

    if (res.error) {
      return { cardsList: [], total: 0, error: res.error.message };
    }

    const cardsList = await Promise.all(
      (res.items || []).map(async (item: any) => {
        const _imgSrc = item.fields?.featuredImage?.[0]?.thumbnails?.transformBaseUrl;

        const tmp = {
          id: item.sys.id || "",
          imgSrc: _imgSrc ?? "/placeholders/no-preview.png",
          placeholder: await getPlaceholderImage(_imgSrc ?? "/placeholders/no-preview.png"),
          title: item.fields?.title || "",
          articleUrl: `/solutions/outfittings/${item.fields?.slug}`,
          buttonLabel: view_package,
          content: item.fields?.excerpt,
          type: "solution",
        };

        return tmp;
      })
    );

    return { cardsList, total: res.total };
  } catch (error: any) {
    console.log(error);

    return { cardsList: [], total: 0, error: error.message };
  }
};
