/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import { SwiperSlide } from "swiper/react";

import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import { Check24 } from "@/assets/Icons";
import RichTextRenderer from "../RichTextRenderer";
import { AppButton } from "../buttons/AppButton";

import Carousel from "../Carousel";

interface IContentCard {
  id: string;
  content: any;
}

interface IContentCarousel {
  id: string;
  heading?: string;
  subheading?: string;
  label?: string;
  ctaLabel?: string;
  ctaLink?: string;
  cards: IContentCard[];
}

const ContentCarouselNew = ({ id, heading, subheading, cards, label, ctaLink, ctaLabel }: IContentCarousel) => {
  return (
    <>
      <ContentLayout bgColorClass="bg-white" id={id}>
        <div className="lg:flex-row flex flex-col justify-between gap-10">
          {(label || heading || subheading) && (
            <div className="flex flex-col gap-2 max-w-[736px]">
              {label && (
                <AppText type={"LABEL_LARGE"} className="text-dijon">
                  {label}
                </AppText>
              )}
              {heading && <AppText type={"HEADLINE_MEDIUM"}>{heading}</AppText>}
              {subheading && <AppText type={"SUB_HEADING_SMALL"}>{subheading}</AppText>}
            </div>
          )}

          {ctaLabel && (
            <div className="">
              <AppButton intent="secondary" label={ctaLabel} link={ctaLink} />
            </div>
          )}
        </div>

        <Carousel
          wrapperClassName={"mt-[40px] flex flex-1"}
          className={"flex-1 flex items-stretch"}
          paginationType={"pageSelect"}
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 40,
            },
          }}
        >
          {cards.map((card, idx) => {
            return (
              <SwiperSlide key={card.id}>
                <Card card={card} idx={idx} />
              </SwiperSlide>
            );
          })}
        </Carousel>
      </ContentLayout>
    </>
  );
};

export default ContentCarouselNew;

const Card = ({ card, idx }: { card: IContentCard; idx: number }) => {
  const { id, content } = card;

  return (
    <div className="flex flex-col p-8  bg-navy-blue-10 h-full rounded-[3px]">
      <div className="bg-royal-blue fill-white w-7 h-7 flex items-center justify-center mb-6 rounded-full">
        <Check24 />
      </div>

      <RichTextRenderer richTextDocument={content} id={id + idx} locations empoweringEmployees />
    </div>
  );
};
