/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDown, ChevronUp } from "lucide-react";

import AppText from "@/components/AppText";
import ContentLayout from "@/components/layouts/ContentLayout";
import RichTextRenderer from "./RichTextRenderer";

interface IFaqSection {
  id: string;
  question: string;
  answer: any;
}

interface IFaqAccordion {
  id?: string;
  heading: string;
  faqs: IFaqSection[];
}

const FAQList = ({ id, heading, faqs }: IFaqAccordion) => {
  const [openSections, setOpenSections] = useState<IFaqSection[]>([]);

  const onSectionClick = (section: IFaqSection) =>
    setOpenSections((prevState) =>
      prevState.includes(section) ? prevState.filter((item) => item !== section) : [...prevState, section]
    );

  const renderSection = (section: IFaqSection, index: number, faqs: IFaqSection[]) => {
    const isOpen = openSections.includes(section);
    const isLast = index === faqs.length - 1;

    return (
      <div key={section.id} className={"cursor-pointer"} onClick={() => onSectionClick(section)}>
        <hr className={"border-black-20"} />
        <div className={"p-[24px] overflow-hidden"}>
          <div className={"flex items-center px-[0px]"}>
            <AppText type={"BODY_LARGE_EMPHASIS"} className={"flex flex-1"}>
              {section.question}
            </AppText>
            {isOpen ? <ChevronUp aria-label="chevron up icon" /> : <ChevronDown aria-label="chevron down icon" />}
          </div>
          <Transition
            show={isOpen}
            unmount={true}
            enter="transition-all duration-500"
            leave="transition-all duration-500"
            enterFrom="transform scale-98 opacity-0 max-h-0"
            enterTo="transform scale-100 opacity-100 max-h-96"
            leaveFrom="transform scale-100 opacity-100 max-h-96"
            leaveTo="transform scale-98 opacity-0 max-h-0"
          >
            <RichTextRenderer richTextDocument={section.answer} id={`rt-${id}`} locations className="pt-6" />
          </Transition>
        </div>
        {isLast && <hr className={"border-black-20"} />}
      </div>
    );
  };

  return (
    <ContentLayout bgColorClass="bg-white" id={id}>
      <div className="flex flex-col gap-6">
        {heading && (
          <AppText type="HEADLINE_SMALL" as="h2" className="mb-[48px]">
            {heading}
          </AppText>
        )}
        <div className="flex-1">{faqs.map(renderSection)}</div>
      </div>
    </ContentLayout>
  );
};

export default React.memo(FAQList);
