"use client";

import React from "react";
import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import { AppIconButton } from "../buttons/AppIconButton";
import { Download } from "lucide-react";
// import { Play } from "@/assets/Icons";
import { colorsMap, fallbackColors } from "../constants";
import { cn } from "@/lib/utils";

interface IOutfittingPackagesHeader {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  video: string;
  videoPoster: string;
  background: string;
  videoAlign: string;
  ctaTheme: "primary" | "secondary" | "ghost";
}

const OutfittingPackagesHeader = ({
  id,
  label,
  heading,
  subheading,
  ctaLabel,
  ctaLink,
  video,
  videoPoster,
  background,
  ctaTheme,
  videoAlign,
}: IOutfittingPackagesHeader) => {
  // const videoRef = useRef<HTMLVideoElement>(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  const { labelColor, textColor, bgColorClass, bg } = colorsMap[background] ?? fallbackColors;

  // async function onVideoClick() {
  //   if (videoRef.current?.paused) {
  //     await videoRef.current?.play();
  //   } else {
  //     videoRef.current?.pause();
  //   }
  // }

  const Video = () => (
    <div
      className={cn(
        "lg:col-span-7 relative flex items-center col-span-4",
        videoAlign === "Left" && "order-last lg:order-first"
      )}
    >
      <div>
        <video
          className="object-cover w-full h-full"
          controls={true}
          preload="auto"
          // ref={videoRef}
          // onClick={onVideoClick}
          // onPlaying={() => setIsPlaying(true)}
          // onPause={() => setIsPlaying(false)}
          muted={false}
          autoPlay={false}
          loop={true}
          playsInline={true}
          poster={videoPoster}
        >
          <source src={video} type="video/mp4" />
        </video>

        {/* {!isPlaying && (
          <div
            onClick={onVideoClick}
            className={
              "w-14 h-14 bg-black-20 bg-opacity-80  cursor-pointer rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            }
          >
            <Play />
          </div>
        )} */}
      </div>
    </div>
  );

  const TextBlock = () => (
    <div className="lg:col-span-5 flex flex-col justify-center col-span-4">
      {label && (
        <AppText type="LABEL_LARGE" className={cn("mb-2", labelColor)}>
          {label}
        </AppText>
      )}

      <AppText type="HEADLINE_LARGE" className={cn("mb-4", textColor)}>
        {heading}
      </AppText>

      <AppText type="SUB_HEADING_SMALL" className={cn("mb-8", textColor)}>
        {subheading}
      </AppText>

      <AppIconButton
        intent={ctaTheme}
        iconOnRightSide
        icon={<Download size={16} aria-label="download icon" />}
        label={ctaLabel}
        link={ctaLink}
        bg={bg}
      />
    </div>
  );

  return (
    <ContentLayout
      id={id}
      bgColorClass={bgColorClass}
      innerClass={cn(
        "2xl:pr-[104px] 2xl:pl-[104px] overflow-hidden",
        videoAlign === "Left"
          ? "lg:pr-[80px] lg:pl-0 xl:pr-[80px] xl:pl-0"
          : "lg:pr-0 lg:pl-[80px] xl:pr-0 xl:pl-[80px] "
      )}
    >
      <div className="lg:grid-cols-12 lg:gap-6 xl:gap-10 grid grid-cols-4 gap-8">
        {videoAlign === "Left" ? (
          <>
            <Video />
            <TextBlock />
          </>
        ) : (
          <>
            <TextBlock />
            <Video />
          </>
        )}
      </div>
    </ContentLayout>
  );
};

export default OutfittingPackagesHeader;
