"use client";

import React from "react";
import { SwiperSlide } from "swiper/react";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { cn } from "@/lib/utils";
import { AppButton } from "../buttons/AppButton";
import Carousel from "@/components/Carousel";
import { colorsMap } from "../constants";

interface IHistoryCard {
  id: string;
  key: string;
  value: string;
}

interface ITimelineCarousel {
  id: string;
  label?: string;
  heading: string;
  subheading?: string;
  timelineCards: IHistoryCard[];
  ctaLink?: string;
  ctaTheme: "primary" | "secondary";
  ctaLabel?: string;
}

const TimelineCarousel: React.FC<ITimelineCarousel> = ({
  id,
  label,
  heading,
  subheading,
  timelineCards,
  ctaLink,
  ctaLabel,
  ctaTheme = "secondary",
}) => {
  const { labelColor, textColor, bgColorClass, bg, border } = colorsMap["navy-blue"];

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id}>
      {label && (
        <AppText className={cn("mb-1", labelColor)} type={"LABEL_LARGE"}>
          {label}
        </AppText>
      )}

      <div className="lg:grid-cols-12 lg:gap-6 xl:gap-10 gap-y-8 grid grid-cols-4 gap-2">
        <div className="flex col-span-4 lg:col-span-8 flex-col flex-[0.8] mr-[40px]">
          <AppText type={"HEADLINE_MEDIUM"} className={textColor}>
            {heading}
          </AppText>

          {subheading && (
            <AppText type={"SUB_HEADING_SMALL"} className={cn("mt-[16px]", textColor)}>
              {subheading}
            </AppText>
          )}
        </div>

        {ctaLabel && (
          <div className="lg:justify-end flex col-span-4">
            <AppButton bg={bg} intent={ctaTheme} label={ctaLabel} link={ctaLink} />
          </div>
        )}
      </div>

      <Carousel
        wrapperClassName={"mt-[40px] flex"}
        className={"flex-1 flex items-stretch"}
        slidesPerView={1}
        spaceBetween={10}
        paginationType={"pageSelect"}
        bg={bg}
        cardsOverflow={true}
        breakpoints={{
          640: {
            slidesPerView: 1.8,
            slidesPerGroup: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2.5,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 3.4,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
          1536: {
            slidesPerView: 4,
            slidesPerGroup: 1,
            spaceBetween: 40,
          },
        }}
      >
        {timelineCards.map((card) => renderCards(card, textColor, border))}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(TimelineCarousel);

const renderCards = (card: IHistoryCard, textColor: string, border: string) => {
  const { id, key, value } = card;

  return (
    <SwiperSlide key={id}>
      <div className={cn("flex flex-col items-start gap-2 border-l-[1px] pl-8 h-full", border)}>
        <AppText className={cn("line-clamp-3 mb-4", textColor)} type="TITLE_LARGE">
          {key}
        </AppText>

        <AppText className={cn("line-clamp-3 mb-4", textColor)} type="BODY_MEDIUM">
          {value}
        </AppText>
      </div>
    </SwiperSlide>
  );
};
