/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { isMobilePhone } from "validator";
import { useRouter } from "next/navigation";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { APIProvider } from "@vis.gl/react-google-maps";
import { toast } from "sonner";
import { fromAddress, OutputFormat, setDefaults } from "react-geocode";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { fr } from "date-fns/locale";

import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input /* PhoneNumberInput */ } from "@/components/ui/input";
import { AppButton } from "@/components/buttons/AppButton";
import { Button as CNButton } from "@/components/ui/button";
import AppText from "@/components/AppText";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { GeocodingResponse, INDUSTRY_DESCRIPTION_MAP, NEW_CARDS } from "./constants";
import { cn } from "@/lib/utils";
import { Textarea } from "@/components/ui/textarea";
import { FormCardCheckbox, FormCardRadio } from "./Cards";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import SiteInput from "./SiteInput";
import { BorderNumber } from "@/components/BorderNumber";
import { preparePayload } from "./helper";
import CustomFormLabel from "@/components/CustomFormLabel";
import ContentLayout from "@/components/layouts/ContentLayout";
import { IDict } from "@/components/constants";
import { CustomFormMessage } from "@/components/CustomFormMessage";

let addressInterval: any;

interface IRquestQuoteForm {
  isFrench: boolean;
  formCopy: IDict;
  durationArray: string[];
  industriesArray: string[];
}

interface ICheckBox {
  [key: string]: boolean;
}

setDefaults({
  key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!,
  language: "en",
  outputFormat: OutputFormat.JSON,
});

const RequestQuoteForm = ({ isFrench, formCopy, durationArray, industriesArray }: IRquestQuoteForm) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: 1 } | Record<string, never>>({});
  const [radioCards, setRadioCards] = useState<{ [key: string]: string | undefined } | Record<string, never>>({});
  const [checkBoxCard, setCheckBoxCard] = useState<ICheckBox>({
    "store-secure-option-1": false,
    "store-secure-option-2": false,
    "store-secure-option-3": false,
  });
  const errorMessageShown = useRef(false);

  const industriesRef = useRef<HTMLInputElement | null>(null);
  const durationRef = useRef<HTMLInputElement | null>(null);
  const otherIndustryRef = useRef<HTMLInputElement | null>(null);
  const cardsRef = useRef<HTMLDivElement | null>(null);
  const siteAddress = useRef("");

  const router = useRouter();

  const baseSchema = z.object({
    FirstName: z.string().min(2, { message: formCopy.min2char }).max(50, { message: formCopy.max50char }),
    LastName: z.string().min(2, { message: formCopy.min2char }).max(50, { message: formCopy.max50char }),
    Email: z.string().email({ message: formCopy.invalidEmail }),
    quote_company_name: z.string(),
    cards: z.string().min(1, { message: formCopy.chooseAtLeastOneCard }),
    quote_duration: z.string().min(1, { message: formCopy.required }),
    Phone: z.string().refine(isMobilePhone, {
      message: formCopy.invalidPhone,
    }),
    deliveryDate: z.date({
      required_error: formCopy.required,
    }),
    googlePlaces: z.string().min(1, { message: formCopy.required }),
    quote_anythingElse: z.string().max(2000, { message: "Maximum 2000 characters" }).optional(),
    // receiveUpdates: z.boolean().default(false).optional(),
    "small-storeSecure": z.boolean().default(false).optional(),
    "medium-storeSecure": z.boolean().default(false).optional(),
    "large-storeSecure": z.boolean().default(false).optional(),
    Site_State__c: z.string().min(1, { message: formCopy.required }),
    Site_Zip__c: z.string().min(1, { message: formCopy.required }),
    Site_City__c: z.string().min(1, { message: formCopy.required }),
    Site_Country__c: z.string().min(1, { message: formCopy.required }),
  });

  const otherIndustriesSchema = z.object({
    quote_industries: z.literal("industries-dropdown-option-7-title", {
      errorMap: () => ({ message: formCopy.required || "Required" }),
    }),
    quote_other_industries: z.string().min(1, { message: formCopy.required }),
  });

  const industriesSchema = z.object({
    quote_industries: z.enum([
      "industries-dropdown-option-1-title",
      "industries-dropdown-option-2-title",
      "industries-dropdown-option-3-title",
      "industries-dropdown-option-4-title",
      "industries-dropdown-option-5-title",
      "industries-dropdown-option-6-title",
    ]),
    quote_other_industries: z.string().optional(),
  });

  const formSchema = z
    .discriminatedUnion("quote_industries", [otherIndustriesSchema, industriesSchema])
    .and(baseSchema);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      quote_company_name: "",
      quote_industries: undefined,
      quote_other_industries: "",
      quote_duration: "",
      cards: "",
      Phone: "",
      googlePlaces: "",
      quote_anythingElse: "",
      "small-storeSecure": false,
      "medium-storeSecure": false,
      "large-storeSecure": false,
      Site_State__c: "",
      Site_Zip__c: "",
      Site_City__c: "",
      Site_Country__c: "",
    },
    mode: "onBlur",
  });

  const oldFieldsValues: any = useRef(form.getValues());
  const watchAllFields: any = form.watch();
  let target: any = "";

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    for (target of Object.keys(watchAllFields)) {
      if (watchAllFields?.[target] !== oldFieldsValues?.current?.[target]) {
        form.trigger(target);
      }
    }

    oldFieldsValues.current = watchAllFields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAllFields]);

  const getSiteData = async () => {
    if (selectedPlace?.formatted_address) {
      const data = (await fromAddress(selectedPlace?.formatted_address)) as GeocodingResponse;

      let city = "",
        state = "",
        zipCode = "",
        address = "",
        streetNumber = "",
        neighborhood = "",
        country = "";
      const components = data.results[0].address_components || [];
      const formatted_address = data.results[0].formatted_address as string;

      for (const target of components) {
        const types = target.types || [];

        if (types.includes("locality")) {
          city = target.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          state = target.long_name;
          // const stateData = JSON.stringify({ long: target.long_name, short: target.short_name });
          // localStorage.setItem("siteState", stateData);
        } else if (types.includes("postal_code")) {
          zipCode = target.long_name;
        } else if (types.includes("route")) {
          address = target.long_name;
        } else if (types.includes("neighborhood")) {
          neighborhood = target.long_name;
        } else if (types.includes("street_number")) {
          streetNumber = target.long_name;
        } else if (types.includes("country")) {
          country = target.long_name;
        }
      }

      form.setValue("Site_Country__c", country);
      form.setValue("Site_State__c", state);
      form.setValue("Site_Zip__c", zipCode);
      form.setValue("Site_City__c", city);
      if (address) {
        if (streetNumber) {
          siteAddress.current = `${streetNumber} ${address}`;
        } else {
          siteAddress.current = `${address}`;
        }
      } else if (formatted_address) {
        siteAddress.current = formatted_address;
      } else {
        siteAddress.current = neighborhood;
      }

      setTimeout(() => {
        form.trigger("Site_Country__c");
        form.trigger("Site_State__c");
        form.trigger("Site_Zip__c");
        form.trigger("Site_City__c");
      }, 500);
    }
  };

  useEffect(() => {
    clearInterval(addressInterval);
    if (selectedPlace) {
      const tmp = { ...errors };

      delete tmp.selectedPlace;

      setErrors(tmp);
      getSiteData();
      form.setValue("googlePlaces", "valid");
    } else {
      form.setValue("googlePlaces", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlace]);

  const watchIndustries = form.watch("quote_industries");

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setSubmitting(true);

    const payload = await preparePayload(values, radioCards, checkBoxCard, formCopy, siteAddress?.current);

    try {
      const response = await fetch("/api/request-quote", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.error) {
        console.log(data);

        toast.error("Error submitting request");
        setSubmitting(false);
      } else {
        router.push("/request-quote/thank-you");
      }
    } catch (error) {
      toast.error("Error submitting request");
      console.log(error);
      setSubmitting(false);
    }
  }

  const updateRadioButtons = (group: string, value: string, del: boolean) => {
    const temp = { ...radioCards };

    if (del) {
      if (temp[group] && temp[group] === value) {
        temp[group] = undefined;

        setRadioCards(temp);
      }
    } else {
      if (temp[group]) {
        if (temp[group] === value) {
          delete temp[group];
        }
        {
          temp[group] = value;
        }
      } else {
        temp[group] = value;
      }
      setRadioCards(temp);
    }

    const isRadioChecked = Object.keys(temp).filter((item: string) => !!temp[item]);

    const isCheckboxChecked: any = Object.keys(checkBoxCard).filter((item: string) => !!checkBoxCard[item]);

    form.setValue("cards", isRadioChecked.length > 0 || isCheckboxChecked.length > 0 ? "valid" : "");
  };

  const deliveryAddressBlur = () => {
    if (!selectedPlace) {
      setErrors((prev) => ({ ...prev, selectedPlace: 1 }));
    }

    addressInterval = setTimeout(() => {
      checkAfterBlur();
    }, 300);
  };

  const checkAfterBlur = () => {
    if (!selectedPlace) {
      if (!errorMessageShown.current) {
        toast.error("Please select place from suggestions list");
        errorMessageShown.current = true;
      }
    }
  };

  const handleDeliveryAddressOnChange = () => {
    setSelectedPlace(null);
    errorMessageShown.current = false;
  };

  const handleCheckboxes = (item: string, checked: boolean) => {
    const tempCheckBoxes: any = { ...checkBoxCard, [item]: checked };
    const tempRadioButtons = { ...radioCards };

    setCheckBoxCard(tempCheckBoxes);

    const isRadioChecked = Object.keys(tempRadioButtons).filter((item: string) => !!tempRadioButtons[item]);
    const isCheckboxChecked = Object.keys(tempCheckBoxes).filter((item: string) => !!tempCheckBoxes[item]);

    form.setValue("cards", isRadioChecked.length > 0 || isCheckboxChecked.length > 0 ? "valid" : "");
  };

  const handleFormSubmit = () => {
    const btn = document.getElementById("targetSubmit");

    btn && btn.click();
  };

  return (
    <ContentLayout
      bgColorClass="bg-black-5"
      innerClass="!py-0 grid-cols-4 lg:grid-cols-12 grid gap-2 sm:gap-6 xl:gap-[40px] "
    >
      <div className="lg:col-span-10 lg:col-start-2 lg:px-10 pb-[56px] pt-[50px] col-span-4 px-6 bg-white">
        <Form {...form}>
          <form className="flex flex-col bg-white" autoComplete="off" onSubmit={form.handleSubmit(onSubmit)}>
            <div className=" flex flex-col">
              {/**---------------------------------- block 1 */}
              <div className="flex gap-[18px]">
                <BorderNumber number={1} />
                <div className="flex-1 flex flex-col pb-[60px] gap-[12px]">
                  <AppText type="TITLE_MEDIUM">{formCopy["question-1-title"]}</AppText>
                  {/**---------------------------------- first name */}
                  <div className="lg:flex-row flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name="FirstName"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["first-name-label"]}*`} />

                          <FormControl>
                            <Input
                              placeholder={formCopy["first-name-placeholder"]}
                              {...field}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />

                    {/**---------------------------------- last name */}
                    <FormField
                      control={form.control}
                      name="LastName"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["last-name-label"]}*`} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["last-name-placeholder"]}
                              {...field}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />
                  </div>

                  {/**---------------------------------- email */}
                  <div className="lg:flex-row flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name="Email"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["email-label"]}*`} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["email-placeholder"]}
                              {...field}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />

                    {/**---------------------------------- phone */}
                    <FormField
                      control={form.control}
                      name="Phone"
                      rules={{
                        validate: (value) =>
                          matchIsValidTel(value ?? "", {
                            onlyCountries: ["US", "CA"],
                          }),
                      }}
                      render={({ field: { value, ref, ...fieldProps }, fieldState }) => {
                        return (
                          <FormItem className="flex flex-col flex-1 space-y-0">
                            <CustomFormLabel label={`${formCopy["phone-label"]}*`} />
                            <div className="h-0 overflow-hidden">
                              <FormControl>
                                <Input
                                  aria-label="telInputScrollTarget"
                                  id="telInputScrollTarget"
                                  className="hiddenInput"
                                  ref={ref}
                                  tabIndex={-1}
                                  autoComplete="nope"
                                  aria-describedby={undefined}
                                  type="presentation"
                                />
                              </FormControl>
                            </div>
                            <div className="telInputCustom focus-within:ring-[2px] ring-offset-2 ring-blue-500/50 outline-none">
                              <FormControl>
                                <MuiTelInput
                                  aria-describedby={undefined}
                                  {...fieldProps}
                                  inputProps={{ style: { padding: "0.5rem" } }}
                                  defaultCountry={"US"}
                                  onlyCountries={["US", "CA"]}
                                  value={value ?? ""}
                                  error={fieldState.error ? true : false}
                                />
                              </FormControl>
                            </div>
                            <FormMessage className="font-saira text-14 font-[400]" />
                          </FormItem>
                        );
                      }}
                    />
                  </div>

                  {/**---------------------------------- company name */}
                  <div className="lg:flex-row flex flex-col gap-6">
                    <FormField
                      control={form.control}
                      name="quote_company_name"
                      render={({ field }) => (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={formCopy["company-name-label"] || ""} />
                          <FormControl>
                            <Input
                              placeholder={formCopy["company-name-placeholder"]}
                              {...field}
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </div>

              {/**---------------------------------- industries dropdown - block 2 */}
              <div className="flex gap-[18px]">
                <BorderNumber number={2} />

                <div className="pb-[60px] flex-1">
                  <AppText type="TITLE_MEDIUM">{formCopy["question-2-title"]}</AppText>

                  <AppText type="BODY_MEDIUM">{formCopy["question-2-description"]}</AppText>

                  <div className="flex flex-col gap-6 mt-10">
                    <FormField
                      control={form.control}
                      name="quote_industries"
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <FormItem className="w-full space-y-0" ref={industriesRef}>
                            <p
                              className={cn(
                                "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                                error && "text-red-500"
                              )}
                            >
                              {`${formCopy["industries-dropdown-label"]}*`}
                            </p>
                            <FormControl>
                              <Select onValueChange={field.onChange} value={field.value}>
                                <SelectTrigger
                                  title="Choose one of the industries"
                                  extra={formCopy[field.value]}
                                  className="!mt-0 border-black"
                                  aria-describedby={undefined}
                                  {...field}
                                >
                                  <SelectValue placeholder={formCopy["industries-dropdown-placeholder"]} />
                                </SelectTrigger>

                                <SelectContent className="selectContentRadix">
                                  <SelectGroup className="flex flex-col gap-2">
                                    <SelectLabel className="hidden1">
                                      {formCopy["industries-dropdown-label"]}
                                    </SelectLabel>

                                    {industriesArray.map((ind, idx) => (
                                      <SelectItem key={idx} value={ind} className="overflow-hidden">
                                        <div className=" cursor-pointer">
                                          <div className="font-saira font-medium text-[#24262E] tracking-[-0.1px] font-size-4">
                                            {formCopy[ind]}
                                          </div>
                                          <div className="font-saira text-[#24262E] tracking-[-0.1px] font-size-4 break-all whitespace-wrap w-full">
                                            {formCopy[INDUSTRY_DESCRIPTION_MAP[ind]]}
                                          </div>
                                        </div>
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </FormControl>
                            {form?.formState?.errors?.quote_industries && (
                              <p className="text-red-500 font-saira text-14 font-[400]">{formCopy["required"]}</p>
                            )}
                          </FormItem>
                        );
                      }}
                    />

                    {/**---------------------------------- other industries */}
                    <div className="flex-1" ref={otherIndustryRef}>
                      <FormField
                        control={form.control}
                        name="quote_other_industries"
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <FormItem
                              className={cn(
                                "flex-1 w-full space-y-0",
                                watchIndustries === "industries-dropdown-option-7-title" ? "block" : "hidden"
                              )}
                            >
                              <p
                                className={cn(
                                  "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                                  error && "text-red-500"
                                )}
                              >{`${formCopy["other-industry-label"]}*`}</p>
                              <FormControl>
                                <Input
                                  aria-label="other industry"
                                  placeholder={formCopy["other-industry-placeholder"]}
                                  className="border-black"
                                  autoComplete="nope"
                                  {...field}
                                  aria-describedby={undefined}
                                />
                              </FormControl>
                              <FormMessage className="font-saira text-14 font-[400]" />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/**---------------------------------- cards block */}
            <div className="flex gap-[18px]">
              <BorderNumber number={3} />

              <div className="pb-[60px] flex-1" ref={cardsRef}>
                <AppText type="TITLE_MEDIUM" className="flex flex-col mb-4">
                  {formCopy["question-3-title"]} <br />
                  {formCopy["question-3-title-second-row"]}
                </AppText>

                <AppText type="BODY_MEDIUM">{formCopy["question-3-description"]}</AppText>

                <FormField
                  control={form.control}
                  name="cards"
                  render={({ field }) => {
                    return (
                      <FormItem className="flex-1 space-y-0 overflow-hidden">
                        <div className="h-0 overflow-hidden">
                          <FormControl>
                            <Input
                              aria-label="cards target"
                              {...field}
                              className="hiddenInput"
                              aria-describedby={undefined}
                            />
                          </FormControl>
                        </div>
                        <FormMessage className="font-saira text-14 font-[400]" />
                      </FormItem>
                    );
                  }}
                />

                <div className="sm:grid-cols-8 grid grid-cols-4 gap-3 pt-3">
                  {NEW_CARDS.map(
                    (
                      {
                        img,
                        title,
                        children,
                        description,
                        label = "",
                      }: {
                        img: string;
                        title: string;
                        children: string[];
                        description: string;
                        label?: string;
                      },
                      index: number
                    ) => {
                      if (index === 1) {
                        return (
                          <FormCardCheckbox
                            key={title}
                            title={formCopy[title]}
                            label={formCopy[label]}
                            img={formCopy[img]}
                            description={formCopy[description]}
                            checkboxes={children}
                            checkBoxCard={checkBoxCard}
                            handleCheckboxes={handleCheckboxes}
                            formCopy={formCopy}
                          />
                        );
                      } else {
                        return (
                          <FormCardRadio
                            key={title}
                            title={title}
                            label={formCopy[label]}
                            img={formCopy[img]}
                            description={formCopy[description]}
                            updateRadioButtons={updateRadioButtons}
                            options={children}
                            radioCardsGroupValue={radioCards[title]}
                            formCopy={formCopy}
                          />
                        );
                      }
                    }
                  )}
                </div>
              </div>
            </div>

            {/**---------------------------------- duration block */}
            <div className="flex gap-[18px]">
              <BorderNumber number={4} />

              <div className="pb-[60px] w-full">
                <AppText type="TITLE_MEDIUM" className="mb-4">
                  {formCopy["question-4-title"]}
                </AppText>

                <FormField
                  control={form.control}
                  name="quote_duration"
                  render={({ field, fieldState: { error } }) => (
                    <FormItem className="flex-1 space-y-0" ref={durationRef}>
                      <p
                        className={cn(
                          "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                          error && "text-red-500"
                        )}
                      >{`${formCopy["duration-label"]}*`}</p>
                      <FormControl>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <SelectTrigger
                            className="!mt-0 border-black placeholder:text-[#64748b]"
                            ref={field.ref}
                            title={`${formCopy["duration-label"]}*`}
                          >
                            <SelectValue
                              placeholder={formCopy["duration-placeholder"]}
                              className="placeholder:text-[#64748b]"
                            />
                          </SelectTrigger>

                          <SelectContent>
                            {durationArray.map((dur, idx) => (
                              <SelectItem aria-label={dur} key={idx} value={dur}>
                                {formCopy[dur]}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="font-saira text-14 font-[400]" />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            {/**---------------------------------- site info block */}
            <div className="flex gap-[18px]">
              <BorderNumber number={5} />

              <div className="pb-[60px] w-full flex flex-col gap-[12px]">
                <AppText type="TITLE_MEDIUM" className="mb-4">
                  {formCopy["question-5-title"]}
                </AppText>

                {/**---------------------------------- delivery address */}
                <div className="lg:flex-row flex flex-col gap-6">
                  <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!}>
                    <div className="flex-1">
                      <FormField
                        control={form.control}
                        name="googlePlaces"
                        render={({ field }) => {
                          return (
                            <FormItem className="flex-1 space-y-0">
                              <div className="h-0 overflow-hidden">
                                <FormControl>
                                  <Input
                                    {...field}
                                    className="hiddenInput"
                                    tabIndex={-1}
                                    autoComplete="nope"
                                    aria-describedby={undefined}
                                  />
                                </FormControl>
                              </div>

                              <SiteInput
                                label={`${formCopy["delivery-address-label"]}*`}
                                setSelectedPlace={setSelectedPlace}
                                deliveryAddressBlur={deliveryAddressBlur}
                                error={!!errors?.selectedPlace}
                                handleDeliveryAddressOnChange={handleDeliveryAddressOnChange}
                                placeholder={formCopy["delivery-address-placeholder"]}
                              />
                              <FormMessage className="font-saira text-14 font-[400]" />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </APIProvider>

                  {/**---------------------------------- date */}
                  <FormField
                    control={form.control}
                    name="deliveryDate"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex flex-col flex-1 space-y-0">
                        <p
                          className={cn(
                            "font-saira text-14 font-[400] tracking-[-0.1px] leading-[1.7]",
                            error && "text-red-500"
                          )}
                        >{`${formCopy["delivery-date-label"]}*`}</p>
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <CNButton
                                aria-describedby={undefined}
                                ref={field.ref}
                                variant={"outline"}
                                className={cn(
                                  "pl-3 text-left font-normal !mt-0 border-black focus-visible:ring-blue-500/50",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value ? (
                                  format(field.value, "PPP")
                                ) : (
                                  <span className="text-[#64748b]">{formCopy["delivery-date-placeholder"]}</span>
                                )}
                                <CalendarIcon className="w-4 h-4 ml-auto opacity-50" aria-label="calendar icon" />
                              </CNButton>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              locale={isFrench ? fr : undefined}
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              disabled={(date) => date < new Date() || date > new Date("2100-01-01")}
                              initialFocus
                              className="font-saira"
                            />
                          </PopoverContent>
                        </Popover>

                        <FormMessage className="font-saira text-14 font-[400]" />
                      </FormItem>
                    )}
                  />
                </div>

                {/** ADDRESS ADDITIONAL FIELDS */}
                <div className="lg:flex-row flex flex-col gap-6">
                  <FormField
                    control={form.control}
                    name="Site_State__c"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <FormItem className="flex-1 space-y-0">
                          <CustomFormLabel label={`${formCopy["state-label"]}*`} />
                          <FormControl>
                            <Input
                              placeholder={`${formCopy["state-placeholder"]}`}
                              {...field}
                              autoComplete={"off"}
                              role="presentation"
                              aria-describedby={undefined}
                            />
                          </FormControl>
                          {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                        </FormItem>
                      );
                    }}
                  />

                  <FormField
                    control={form.control}
                    name="Site_Zip__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["zip-label"]}*`} />
                        <FormControl>
                          <Input
                            placeholder={formCopy["zip-placeholder"]}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />
                </div>

                <div className="lg:flex-row flex flex-col gap-6">
                  <FormField
                    control={form.control}
                    name="Site_City__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["city-label"]}*`} />
                        <FormControl>
                          <Input
                            placeholder={formCopy["city-placeholder"]}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="Site_Country__c"
                    render={({ field, fieldState: { error } }) => (
                      <FormItem className="flex-1 space-y-0">
                        <CustomFormLabel label={`${formCopy["country-label"]}*`} />
                        <FormControl>
                          <Input
                            placeholder={formCopy["country-placeholder"]}
                            {...field}
                            autoComplete={"off"}
                            role="presentation"
                            aria-describedby={undefined}
                          />
                        </FormControl>
                        {error?.message && <CustomFormMessage>{error.message}</CustomFormMessage>}
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            {/**---------------------------------- textarea block */}
            <div className="flex gap-[18px]">
              <BorderNumber number={6} noBorder />

              <div className="pb-[60px] flex-1">
                <AppText type="TITLE_MEDIUM" className="mb-4">
                  {formCopy["question-6-title"]}
                </AppText>

                <AppText type="BODY_MEDIUM">{formCopy["question-6-description"]}</AppText>

                <div className="mt-10">
                  <FormField
                    control={form.control}
                    name="quote_anythingElse"
                    render={({ field }) => {
                      return (
                        <FormItem className="flex-1 space-y-0">
                          <Textarea
                            aria-label="textarea for additional info"
                            id="textarea-anything-else"
                            placeholder={formCopy["question-6-placeholder"]}
                            maxLength={5000}
                            {...field}
                          />
                          <FormMessage className="font-saira text-14 font-[400]" />
                        </FormItem>
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            {/******************* SUBMIT BUTTON ************************ */}
            <div className="bg-white flex flex-row-reverse flex-wrap justify-start px-[40px] mt-10">
              {submitting ? (
                <button
                  id="button-request-quote"
                  className={`bg-black-20 text-black-60 px-10 disabled:cursor-not-allowed py-2
               transition-all duration-200 rounded-md outline-none gap-3 w-fit select-none flex items-center`}
                  disabled
                >
                  <div
                    className={`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid 
                  border-current border-e-transparent align-[-0.125em] 
                  motion-reduce:animate-[spin_1.5s_linear_infinite]`}
                  ></div>
                  <AppText type={"BUTTON_SMALL"} className={cn("transition whitespace-nowrap opacity-60")}>
                    {formCopy.submitting}
                  </AppText>
                </button>
              ) : (
                <>
                  <button id="targetSubmit" aria-label="submit target" className="hidden" type="submit"></button>
                  <AppButton
                    intent="primary"
                    label={formCopy.submit || "Submit"}
                    type="submit"
                    className="px-10"
                    disabled={submitting}
                    onMouseDown={handleFormSubmit}
                  />
                </>
              )}
            </div>
          </form>
        </Form>
      </div>
    </ContentLayout>
  );
};

export default RequestQuoteForm;
