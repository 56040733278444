/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { SwiperSlide } from "swiper/react";
import { ChevronRight } from "lucide-react";

import ContentLayout from "../layouts/ContentLayout";
import AppText from "../AppText";
import AppButton from "../buttons/AppButton";
import { colorsMap, fallbackColors } from "../constants";
import { cn } from "@/lib/utils";
import { AppIconButton } from "../buttons/AppIconButton";
import CustomLink from "../CustomLink";

import CarouselFullWidthShiftedLeft from "./CarouselFullWidthShiftedLeft";
import { CASE_STUDY_CAROUSEL_BREAKPOINTS } from "./constants";

interface ICaseStudyCarouselNew {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  ctaLabel: string;
  ctaLink: string;
  ctaTheme: "secondary" | "primary" | "ghost" | undefined;
  background: string;
  cards: any;
  read_more: string;
}

const CaseStudyCarouselNew = ({
  id,
  label,
  heading,
  subheading,
  ctaLabel,
  ctaLink,
  ctaTheme,
  background,
  cards,
  read_more,
}: ICaseStudyCarouselNew) => {
  const { labelColor, textColor, bgColorClass, bg } = colorsMap[background] ?? fallbackColors;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <>
      <ContentLayout bgColorClass={bgColorClass} id={id} innerClass="sm:pb-0 pb-0">
        {label && (
          <AppText className={labelColor} type={"LABEL_LARGE"}>
            {label}
          </AppText>
        )}

        <div className={"mt-2 max-sm:flex-col flex flex-1 justify-between"}>
          <div className={"flex flex-col flex-[0.8] mr-10"}>
            <AppText type={"HEADLINE_MEDIUM"} className={textColor}>
              {heading}
            </AppText>

            <AppText type={"SUB_HEADING_MEDIUM"} className={cn("mt-4", textColor)}>
              {subheading}
            </AppText>
          </div>

          <div className={"max-sm:mt-8"}>
            <AppButton bg={bg} intent={ctaTheme || "primary"} label={ctaLabel} link={ctaLink} />
          </div>
        </div>
      </ContentLayout>

      {/** full width, shifted left */}
      <CarouselFullWidthShiftedLeft
        bgColorClass={bgColorClass}
        wrapperClassName={cn("relative pt-12 xl:pt-16 px-4 lg:px-0", bgColorClass)}
        bg="dark"
        breakpoints={CASE_STUDY_CAROUSEL_BREAKPOINTS}
      >
        {cards.map((card: any) => (
          <SwiperSlide key={card.id} className="p-[6px]">
            <CustomLink
              href={card.ctaLink}
              className={`hover:cursor-pointer focus-visible:outline-dijon outline-offset-2 ring-0
                flex flex-col overflow-hidden transition h-[100%] group`}
            >
              <div className="relative w-full overflow-hidden aspect-[16/9]">
                <Image
                  fill
                  sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 540px"
                  src={card.image}
                  alt={card.imgAlt}
                  className="object-cover"
                />

                {card.featuredBrand && (
                  <div className="bottom-4 left-4 absolute lg:w-[159px] lg:h-[82px] w-[130px] flex justify-center items-center h-[67px] bg-white">
                    <Image
                      className="object-cover w-full"
                      sizes="100vw"
                      width={0}
                      height={0}
                      src={card.featuredBrand}
                      alt={card.featuredBrand ? `${card.featuredBrand} brand image` : "brand image"}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col items-start flex-1 py-4">
                {card.label && (
                  <AppText className={cn("mb-2 text-royal-blue", labelColor)} type="LABEL_LARGE">
                    {card.label}
                  </AppText>
                )}

                <AppText className={cn("line-clamp-3 mb-4", textColor)} type="TITLE_MEDIUM">
                  {card.title}
                </AppText>

                <AppIconButton
                  tabIndex={-1}
                  intent="ghost"
                  label={read_more}
                  icon={<ChevronRight size={20} aria-label="chevron right icon" />}
                  iconOnRightSide
                  className={cn("mt-auto", textColor)}
                  bg={bg}
                />
              </div>
            </CustomLink>
          </SwiperSlide>
        ))}
      </CarouselFullWidthShiftedLeft>
    </>
  );
};

export default CaseStudyCarouselNew;
