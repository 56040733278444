/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import ContentLayout from "./layouts/ContentLayout";
import AppText from "./AppText";
import { cn } from "@/lib/utils";
import { WorkspaceDot } from "./WorkspaceDot";
import { useMediaQuery } from "@/hooks/mediaQuery";
import Image from "next/image";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useDebounceValue } from "usehooks-ts";
import AppButton from "./buttons/AppButton";

export interface IWorksiteTab {
  id: string;
  label: string;
  imgSrc: string;
  imgAlt: string;
  dots: {
    top: number;
    left: number;
    id: string;
    info: {
      label: string;
      heading: string;
      subheading: string;
      ctaLink?: string;
      ctaLabel?: string;
    };
  }[];
}

export interface IWorksiteExplore {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  tabs: IWorksiteTab[];
  findOutMore: string;
}

let mouseDown = false;
let startX = 0,
  scrollLeft = 300;

const WorksiteExplore = ({ label, heading, subheading, tabs, id, findOutMore }: IWorksiteExplore) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [visibleTooltip, setVisibleTooltip] = useState<{ idx: number; isLeft: boolean } | null>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const [maxScrollLeft, setMaxScrollLeft] = useState(0);
  const [showArrows, setShowArrows] = useState({ left: false, right: true });
  const [debouncedValue, setValue] = useDebounceValue(0, 200);
  const isMobile = useMediaQuery("(max-width: 640px)");

  // const cursorRef = useRef<HTMLDivElement>(null);
  // const Cursor = () => (
  //   <div
  //     ref={cursorRef}
  //     className="bg-[#005EB8] z-2160 flex fixed rounded-full w-[75px] h-[75px] text-white items-center justify-center"
  //   >
  //     <ChevronLeft size={18} aria-label="chevron left icon"/>

  //     <span>Drag</span>
  //     <ChevronRight size={18} />
  //   </div>
  // );

  useLayoutEffect(() => {
    if (heroRef?.current) {
      heroRef.current.scrollLeft = 800;
    }
  }, []);

  useEffect(() => {
    if (maxScrollLeft === debouncedValue) {
      setShowArrows({ left: true, right: false });
    } else if (debouncedValue === 0) {
      setShowArrows({ left: false, right: true });
    } else setShowArrows({ left: true, right: true });
  }, [maxScrollLeft, debouncedValue]);

  const move = (e: MouseEvent) => {
    e.preventDefault();

    // if (cursorRef?.current) {
    //   cursorRef.current.style.left = `${e.clientX - 37}px`;
    //   cursorRef.current.style.top = `${e.clientY - 37}px`;
    // }

    if (!mouseDown || !heroRef.current) {
      return;
    }
    const x = e.pageX - heroRef.current.offsetLeft;
    const scroll = x - startX;
    heroRef.current.scrollLeft = scrollLeft - scroll;
  };

  // const showCursor = () => {
  //   if (cursorRef.current) {
  //     cursorRef.current.style.display = "flex";
  //   }
  // };

  // const hideCursor = useCallback(() => {
  //   if (cursorRef.current) {
  //     cursorRef.current.style.display = "none";
  //   }
  // }, []);

  const scrollListener = () => {
    const newScrollLeft = heroRef?.current?.scrollLeft;
    setValue(newScrollLeft || 0);
  };

  const mainScroll = () => {
    setVisibleTooltip(null);
  };

  const stopDragging = useCallback(() => {
    mouseDown = false;
  }, []);

  const startDragging = (e: MouseEvent) => {
    e.preventDefault();

    mouseDown = true;

    if (heroRef.current) {
      startX = e.pageX - heroRef.current.offsetLeft;
      scrollLeft = heroRef.current.scrollLeft;
    }
  };

  const handleKeyDown = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      setVisibleTooltip(null);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("scroll", mainScroll);

    if (heroRef.current) {
      // heroRef.current.scrollLeft = 600;
      heroRef.current.addEventListener("scroll", scrollListener);
      setMaxScrollLeft(heroRef.current.scrollWidth - heroRef.current.clientWidth);
    }

    // if (heroRef.current) {
    //   heroRef.current.addEventListener("mouseleave", hideCursor);
    //   heroRef.current.addEventListener("mouseenter", showCursor);
    //   heroRef.current.style.cursor = "none";
    // }

    return () => {
      removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("scroll", mainScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (heroRef.current) {
      heroRef.current.addEventListener("mousemove", move);
      heroRef.current.addEventListener("mousedown", startDragging);
      heroRef.current.addEventListener("mouseup", stopDragging);
      heroRef.current.addEventListener("mouseleave", stopDragging);
    }

    return () => {
      if (heroRef.current) {
        heroRef.current.removeEventListener("mousemove", move);
        heroRef.current.removeEventListener("mousedown", startDragging);
        heroRef.current.removeEventListener("mouseup", stopDragging);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        heroRef.current.removeEventListener("mouseleave", stopDragging);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        heroRef.current.removeEventListener("scroll", scrollListener);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopDragging]);

  const handleDotsParentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target && target.id === "dotsParent") setVisibleTooltip(null);
  };

  const handleTooltip = () => setVisibleTooltip(null);

  const handleScroll = (side: string) => {
    if (heroRef.current) {
      if (side === "left") {
        heroRef.current.scrollLeft -= 420;
      } else {
        heroRef.current.scrollLeft += 420;
      }
    }
  };

  return (
    <>
      {/** top text */}
      <ContentLayout
        id={id}
        bgColorClass="bg-[#BCE0EE]"
        innerClass="flex flex-col items-center sm:pb-[2px] pb-[2px]  px-4 lg:px-8"
      >
        {label && (
          <AppText type="LABEL_LARGE" className="text-royal-blue mb-1 text-center">
            {label}
          </AppText>
        )}

        <AppText type="HEADLINE_MEDIUM" className="mb-2 text-center">
          {heading}
        </AppText>

        <AppText type="SUB_HEADING_SMALL" className="text-center">
          {subheading}
        </AppText>

        <div className="flex flex-wrap justify-center gap-4 pb-1 mt-8">
          {tabs.map((tab: IWorksiteTab, idx: number) => {
            const isSelected = activeTab.id === tab.id;

            return (
              <button
                onClick={() => setActiveTab(tabs[idx])}
                key={tab.id}
                className={cn(
                  `outline-none focus-visible:outline-royal-blue rounded-[100px] select-none text-14 leading-[170%] font-[500] px-4 py-1 
                  text-black bg-[#00000010] hover:bg-[#334D7C] hover:text-white font-sairaSemiCondensed`,
                  isSelected && "bg-[#005EB8] active:bg-navy-blue-20 text-white pointer-events-none opacity-100"
                )}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
      </ContentLayout>

      {/** tab buttons */}
      {/* <ButtonsCarousel tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} /> */}

      {/** pannable image */}
      <div className="relative">
        <div
          className="no-scrollbar cursor-grab scroll-smooth relative overflow-x-auto overflow-y-auto"
          ref={heroRef}
          id={activeTab.id}
        >
          {/** background image */}
          <div className="w-[160%] max-h touch-pan-x origin-left min-w-[1800px]">
            <Image
              priority
              unoptimized={true}
              // src={WORKSITE_EXPLORE_HERO_IMAGES[activeTab.id]}
              src={activeTab.imgSrc}
              alt={activeTab.imgAlt}
              className="object-cover w-full"
              sizes="100vw"
              width={0}
              height={0}
            />
          </div>

          {visibleTooltip !== null && (
            <div onClick={handleTooltip} className="fixed top-0 bottom-0 left-0 right-0 z-20 bg-transparent" />
          )}

          {/** dots */}
          <div
            onClick={handleDotsParentClick}
            id="dotsParent"
            className={cn("absolute inset-0 w-[160%] min-w-[1800px] overflow-hidden", visibleTooltip ? "z-50" : "z-30")}
          >
            {activeTab.dots.map((dot: any, idx: number) => {
              return (
                <WorkspaceDot
                  key={dot.id}
                  visibleTooltip={visibleTooltip}
                  setVisibleTooltip={setVisibleTooltip}
                  idx={idx}
                  dot={dot}
                  isMobile={isMobile}
                  closeTooltip={handleTooltip}
                />
              );
            })}
          </div>
        </div>

        <AppButton label={findOutMore} className="worksite-button" bg="dark" link="/industries" intent="primary" />

        <button
          type="button"
          id="button-worksite-explore-handle-scroll-left"
          aria-label="Scroll left"
          onClick={() => handleScroll("left")}
          className={cn(
            "worksite-arrow font-sairaSemiCondensed transition left-6 sm:left-14 z-40 p-2 sm:p-3",
            !showArrows.left && "hidden"
          )}
        >
          <ArrowLeft color="white" size={isMobile ? 18 : 24} aria-label="arrow left icon" />
        </button>

        <button
          type="button"
          id="button-worksite-explore-handle-scroll-right"
          aria-label="Scroll right"
          onClick={() => handleScroll("right")}
          className={cn(
            "worksite-arrow font-sairaSemiCondensed transition right-6 sm:right-14 z-40 p-2 sm:p-3",
            !showArrows.right && "hidden"
          )}
        >
          <ArrowRight color="white" size={isMobile ? 16 : 24} aria-label="arrow right icon" />
        </button>
      </div>
    </>
  );
};

export default WorksiteExplore;

// ! original code for tab buttons, visible on mobile only
// const ButtonsCarousel = ({
//   tabs,
//   activeTab,
//   setActiveTab,
// }: {
//   tabs: IWorksiteTab[];
//   activeTab: IWorksiteTab;
//   setActiveTab: (tab: IWorksiteTab) => void;
// }) => {
//   return (
//     <ContentLayout
//       className="lg:hidden flex"
//       innerClass="sm:py-0 block py-0 sm:pb-8 pb-4 px-0 lg:px-0 xl:px-0 2xl:px-0"
//       bgColorClass="bg-[#BCE0EE]"
//     >
//       <div>
//         <Carousel
//           wrapperClassName={"flex pt-4"}
//           slidesPerView="auto"
//           spaceBetween={0}
//           paginationType={"none"}
//           cardsOverflow={true}
//         >
//           {tabs.map((tab: IWorksiteTab, idx: number) => {
//             const isSelected = activeTab.id === tab.id;

//             return (
//               <SwiperSlide key={tab.id} className="p-2 !w-auto">
//                 <button
//                   id="button-worksite-explore-handle-active-tab"
//                   onClick={() => setActiveTab(tabs[idx])}
//                   key={tab.id}
//                   className={cn(
//                     `outline-none focus-visible:outline-royal-blue rounded-[100px] select-none text-14 leading-[170%] font-[500] px-4 py-1
//                     text-black bg-[#00000010] hover:bg-[#334D7C] hover:text-white font-sairaSemiCondensed`,
//                     isSelected && "bg-royal-blue active:bg-navy-blue-20 text-white pointer-events-none opacity-100"
//                   )}
//                 >
//                   {tab.label}
//                 </button>
//               </SwiperSlide>
//             );
//           })}
//         </Carousel>
//       </div>
//     </ContentLayout>
//   );
// };
