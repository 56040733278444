"use client";

import React from "react";
import { EmblaOptionsType } from "embla-carousel";

import EmblaCarousel from "./embla/emblaImageGallery";
import ContentLayout from "./layouts/ContentLayout";
import AppText from "./AppText";
import { cn } from "@/lib/utils";
import { colorsMap, fallbackColors } from "./constants";

export interface IImageGallery {
  images: { url: string; alt: string; placeholder: string }[];
  id?: string;
  label?: string;
  title?: string;
  subheading?: string;
  background?: string;
}

const OPTIONS: EmblaOptionsType = { loop: true, startIndex: 1 };

const ImageGallery: React.FC<IImageGallery> = ({ images, label, title, subheading, background, id }) => {
  const { labelColor, textColor, bgColorClass } = colorsMap[background || "navy-blue"] ?? fallbackColors;

  return (
    <div className={cn("relative overflow-hidden scroll-smooth", bgColorClass)} id={id}>
      {label && (
        <ContentLayout>
          <div className="flex flex-col items-center">
            {label && (
              <AppText type="LABEL_LARGE" className={cn("text-royal-blue", labelColor)}>
                {label}
              </AppText>
            )}

            <AppText type="HEADLINE_MEDIUM" className={cn("mt-2", textColor)}>
              {title}
            </AppText>

            <AppText type="BODY_MEDIUM" className={cn("mt-4 max-w-[736px] text-center", textColor)}>
              {subheading}
            </AppText>
          </div>
        </ContentLayout>
      )}
      <div className="relative overflow-hidden">
        <EmblaCarousel slides={images} options={OPTIONS} />
      </div>
    </div>
  );
};

// export default ImageGallery;
export default React.memo(ImageGallery);
