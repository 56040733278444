"use client";

import React from "react";
import Image from "next/image";

import ContentLayout from "@/components/layouts/ContentLayout";
import AppText from "@/components/AppText";
import { SwiperSlide } from "swiper/react";
import { cn } from "@/lib/utils";

import { AppButton } from "../buttons/AppButton";
import Carousel from "@/components/Carousel";
import { colorsMap, fallbackColors } from "../constants";
import CustomLink from "../CustomLink";
import { AppIconButton } from "../buttons/AppIconButton";

import { useLocale } from "@/store/clientStore";
import { ChevronRight } from "lucide-react";

interface ICSCard {
  ctaLabel: string;
  ctaLink: string;
  id: string;
  image: string;
  imgAlt: string;
  label: string;
  title: string;
  featuredBrand: string;
  featuredBrandAlt: string;
}
interface CardsCarouselProps {
  id: string;
  label: string;
  heading: string;
  subheading: string;
  posts: ICSCard[];
  ctaLink: string;
  ctaLabel: string;
  background: string;
}

const CaseStudyCarouselMultiCards: React.FC<CardsCarouselProps> = ({
  id,
  label,
  heading,
  subheading,
  posts,
  ctaLink,
  ctaLabel,
  background,
}) => {
  const { labelColor, textColor, bgColorClass, bg } = colorsMap[background] ?? fallbackColors;

  const { locale } = useLocale();

  return (
    <ContentLayout bgColorClass={bgColorClass} id={id}>
      {label && (
        <AppText className={labelColor} type={"LABEL_LARGE"}>
          {label}
        </AppText>
      )}

      <div className={"mt-[8px] max-sm:flex-col flex flex-1 justify-between"}>
        <div className={"flex flex-col flex-[0.8] mr-[40px]"}>
          <AppText type={"HEADLINE_MEDIUM"} className={textColor}>
            {heading}
          </AppText>

          <AppText type={"SUB_HEADING_SMALL"} className={cn("mt-[16px]", textColor)}>
            {subheading}
          </AppText>
        </div>

        <div className={"max-sm:mt-[32px]"}>
          <AppButton bg={bg} intent="secondary" label={ctaLabel} link={ctaLink} />
        </div>
      </div>
      <Carousel
        wrapperClassName={"mt-[40px] flex"}
        className={"flex-1 flex items-stretch"}
        slidesPerView={1}
        spaceBetween={10}
        paginationType={"pageSelect"}
        bg={bg}
        cardsOverflow={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 2.2,
            slidesPerGroup: 3,
            spaceBetween: 40,
          },
          1280: {
            slidesPerView: 2.4,
            slidesPerGroup: 3,
            spaceBetween: 40,
          },
          1536: {
            slidesPerView: 2.7,
            slidesPerGroup: 2,
            spaceBetween: 40,
          },
        }}
      >
        {posts.map((post) => renderArticle(post, bg, locale, textColor, labelColor))}
      </Carousel>
    </ContentLayout>
  );
};

export default React.memo(CaseStudyCarouselMultiCards);

const renderArticle = (
  article: ICSCard,
  bg: "dark" | "light" | undefined,
  locale: string,
  textColor: string,
  labelColor: string
) => {
  const { ctaLabel, ctaLink, id, image, featuredBrandAlt, imgAlt, label, title, featuredBrand } = article;

  return (
    <SwiperSlide key={id} className="p-[6px]">
      <CustomLink
        href={ctaLink}
        lang={locale}
        className={cn(
          `hover:cursor-pointer focus-within:outline-dijon outline-offset-2 ring-0
        flex flex-col overflow-hidden transition h-[100%] group`
        )}
      >
        <div className={`relative w-full overflow-hidden`}>
          <Image
            className={cn(
              "object-cover w-full group-hover:scale-105 transition aspect-[1.75] md:aspect-[1.5] xl:aspect-[1.75] h-[306px] sm:max-w-[540px]"
            )}
            sizes="100vw"
            width={0}
            height={0}
            src={image}
            alt={imgAlt}
          />

          {featuredBrand && (
            <div className="bottom-4 left-4 absolute lg:w-[159px] lg:h-[82px] w-[130px] h-[67px] flex justify-center items-center bg-white">
              <Image
                className="object-cover w-full"
                sizes="100vw"
                width={0}
                height={0}
                src={featuredBrand}
                alt={featuredBrandAlt}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col items-start flex-1 py-4">
          {label && (
            <AppText className={cn("mb-2 text-royal-blue", labelColor)} type="LABEL_LARGE">
              {label}
            </AppText>
          )}

          <AppText className={cn("line-clamp-3 mb-4", textColor)} type="TITLE_LARGE">
            {title}
          </AppText>

          <AppIconButton
            tabIndex={-1}
            intent="ghost"
            label={ctaLabel}
            icon={<ChevronRight size={20} aria-label="chevron right icon" />}
            iconOnRightSide
            className={cn("mt-auto", textColor)}
            bg={bg}
          />
        </div>
      </CustomLink>
    </SwiperSlide>
  );
};
